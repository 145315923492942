import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isNilOrEmpty } from 'ramdasauce'
import MainTextArea from '../inputs/MainTextArea'
import MainInput from '../inputs/MainInput'
import ConfirmationButtons from '../buttons/ConfirmationButtons'
import ErrorHandler from '../error/ErrorHandler'

class SignOffModal extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props)
    this.state = {
      userName: window.sessionHandler.loggedInUser.userName,
      password: '',
      reason: '',
    }
  }

  render() {
    const { userName, password, reason } = this.state
    const { handleCanceled, handleConfirmed, loading, error } = this.props
    return (
      <div>
        { error && this._renderError(error) }
        <MainTextArea
          value={ reason }
          name="reason"
          maxLength={ 1024 }
          label="Describe the reason for change"
          textAreaStyle="data-clarification-form_textarea"
          onInputChanged={ this._handleReasonChange } />
        <MainInput
          value={ userName }
          inputClass="u-text--lowercased"
          label="Username"
          name="userName"
          onInputChanged={ this._handleInputChange }
          returnFullInputEvent={ true } />
        <MainInput
          value={ password }
          inputClass="u-text--lowercased"
          label="Password"
          name="password"
          inputType="password"
          onInputChanged={ this._handleInputChange }
          returnFullInputEvent={ true } />
        <ConfirmationButtons
          onCancel={ this._onCancel(handleCanceled) }
          onConfirm={ this._onConfirm(handleConfirmed, userName, password, reason) }
          cancelDisabled={ loading }
          confirmDisabled={ isNilOrEmpty(reason) || isNilOrEmpty(userName) || isNilOrEmpty(password) || loading }
        />
      </div>
    )
  }

  _onCancel = callback => () => {
    try {
      callback()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _onConfirm = (callback, userName, password, reason) => () => {
    try {
      callback({ userName, password, reason })
    } catch (e) {
      console.log(e)
    }
  }

  _handleInputChange = (event) => {
    const { target } = event
    this.setState({ [target.name]: target.value })
  }

  _handleReasonChange = (reason) => {
    this.setState({ reason })
  }

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--vertical"
      error={ error }
    />
  )
}

SignOffModal.propTypes = {
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
}

SignOffModal.defaultProps = { error: null }

export default SignOffModal
