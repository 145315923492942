import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'

import history from '../utils/HistoryHelper'

import NavbarBrand from '../components/navbar/NavbarBrand'
import NavbarHeader from '../components/navbar/NavbarHeader'

import MainContainer from './layout/MainContainer'
import NavbarContainer from './layout/NavbarContainer'
import SidebarContainer from './layout/SidebarContainer'
import ViewContainer from './layout/ViewContainer'

import PatientContainer from './patient/PatientContainer'

import SiteOverview from './content/SiteOverview'
import StudyContainer from './content/StudyContainer'
import StartContainer from './content/StartContainer'
import LoadingContainer from './content/LoadingContainer'

const routes = [
  {
    id: 'start-container',
    path: '/',
    exact: true,
    main: () => <StartContainer />,
  },
  {
    id: 'study-container',
    exact: true,
    path: '/study/:study_id',
    main: () => <StudyContainer />,
  },
  {
    id: 'site-overview',
    exact: true,
    path: '/study/:study_id/site/:site_id',
    main: () => <SiteOverview />,
  },
  {
    id: 'patient-container',
    path: '/study/:study_id/site/:site_id/patient/:patient_id',
    main: () => <PatientContainer />,
  },
]

export default class RouterContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { sidebarCollapsed: false }
  }

  render() {
    const { sidebarCollapsed } = this.state

    return (
      <ConnectedRouter history={ history }>
        <div className="router-container">
          <NavbarContainer>
            <NavbarBrand />
            <NavbarHeader handleSidebarClick={ this._toggleSidebar(sidebarCollapsed) } />
          </NavbarContainer>
          <ViewContainer>
            <SidebarContainer sidebarCollapsed={ sidebarCollapsed } />
            <MainContainer>
              <LoadingContainer />
              <Switch>
                { routes.map(route => (
                  <Route
                    key={ route.id }
                    path={ route.path }
                    exact={ route.exact }
                    component={ route.main } />
                )) }
              </Switch>
            </MainContainer>
          </ViewContainer>
        </div>
      </ConnectedRouter>
    )
  }

  _toggleSidebar = isSidebarCollapsed => () => {
    this.setState({ sidebarCollapsed: !isSidebarCollapsed })
  }
}
