import React, { useState } from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import Icon from '../Icon'
import { ICON_ARROW_DOWN } from '../../config/constants'
import PatientStudyAssessmentsTableRowSubItem from './PatientStudyAssessmentTableRowSubItem'

function PatientStudyAssessmentTableRowItem(props) {
  const [showSubItems, setShowSubItems] = useState(false)
  const { assessments } = props

  const _renderSubItems = () => (
    assessments.map(assessment => (
      <PatientStudyAssessmentsTableRowSubItem
        content={ assessment.content }
        key={ shortid.generate() } />
    )))

  return (
    <tbody>
      <tr
        className="o-table__item--collapse"
        onClick={ () => setShowSubItems(!showSubItems) }>
        <td>
          <Icon
            name={ ICON_ARROW_DOWN }
            rotate={ showSubItems ? 0 : 270 } />
          <span className="o-table__title">
            Study Assessments
          </span>
        </td>
        <td />
      </tr>
      { showSubItems && _renderSubItems() }
    </tbody>
  )
}


PatientStudyAssessmentTableRowItem.propTypes = { assessments: PropTypes.array.isRequired }

export default PatientStudyAssessmentTableRowItem
