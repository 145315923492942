import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

function PatientPDIHeaderTableRow(props) {
  const { tableHeaders } = props
  return (
    <tr>
      { tableHeaders.map(header => (
        <th key={ shortid.generate() }>
          { header }
        </th>
      )) }
    </tr>
  )
}

PatientPDIHeaderTableRow.propTypes = { tableHeaders: PropTypes.array }

PatientPDIHeaderTableRow.defaultProps = { tableHeaders: [] }

export default PatientPDIHeaderTableRow
