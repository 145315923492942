import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { is, isNil, equals } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'

import ConfirmationButtons from '../buttons/ConfirmationButtons'

import MainInput from '../inputs/MainInput'
import MainTextArea from '../inputs/MainTextArea'

import ErrorHandler from '../error/ErrorHandler'
import LoadingBox from '../loading/LoadingBox'

class ChangePatientIdForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedPatientId: null,
      patientIdOptions: null,
      showSecondConfirmationForm: false,
      reasonDescription: '',
      userName: window.sessionHandler.loggedInUser.userName,
      userPwd: '',
    }
  }

  componentDidMount() {
    const { availablePatientIds } = this.props
    this._setAvailablePatientIds(availablePatientIds)
  }

  componentWillReceiveProps(nextProps) {
    const { availablePatientIds } = this.props
    const nextAvailablePatientIds = nextProps.availablePatientIds
    const receivedNewAvailablePatientIds = availablePatientIds !== nextAvailablePatientIds
    if (receivedNewAvailablePatientIds) {
      this._setAvailablePatientIds(nextAvailablePatientIds)
    }
  }


  render() {
    const { patientIdOptions, selectedPatientId, showSecondConfirmationForm } = this.state
    const { handleCanceled, handleConfirmed, loading, errors } = this.props
    return (
      <div>
        { !isNil(errors.fetchAvailablePatientIdsError) && this._renderError(errors.fetchAvailablePatientIdsError) }
        { !isNil(errors.changePatientIdError) && this._renderError(errors.changePatientIdError) }
        { loading.busyFetchingAvailablePatientIds && this._renderLoadingAvailablePatientIds() }
        { !loading.busyFetchingAvailablePatientIds && isNil(errors.fetchAvailablePatientIdsError) && !showSecondConfirmationForm && this._renderPatientIdForm(selectedPatientId, handleCanceled, patientIdOptions) }
        { !loading.busyFetchingAvailablePatientIds && isNil(errors.fetchAvailablePatientIdsError) && showSecondConfirmationForm && this._renderConfirmationForm(selectedPatientId, handleCanceled, handleConfirmed, loading.busyChangingPatientId) }
      </div>
    )
  }

  _renderLoadingAvailablePatientIds = () => (
    <LoadingBox
      size={ 16 }
      message="Loading available patient IDs" />
  )

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  _renderPatientIdForm(selectedPatientId, handleCanceled, patientIdOptions) {
    return (
      <div>
        <Select
          className="portal-select-input u-margin--"
          name="patient-select"
          options={ patientIdOptions }
          placeholder="Select new patient ID"
          value={ selectedPatientId }
          onChange={ this._selectedPatientIdChanged }
          id="dropdown-select-patient" />
        <ConfirmationButtons
          onCancel={ this._onCancel(handleCanceled) }
          onConfirm={ this._onFirstConfirm }
          confirmLabel="Continue"
          confirmDisabled={ isNilOrEmpty(selectedPatientId) }
        />
      </div>
    )
  }

  _renderConfirmationForm(selectedPatientId, handleCanceled, handleConfirmed, loading) {
    const { userName, userPwd, reasonDescription } = this.state
    return (
      <div>
        <MainTextArea
          value={ reasonDescription }
          label={ `Describe the reason for changing the patient's ID to ${selectedPatientId.value}` }
          name="reasonDescription"
          maxLength={ 250 }
          textAreaStyle="data-clarification-form_textarea"
          onInputChanged={ this._handleReasonChange } />
        <MainInput
          value={ userName }
          inputClass="u-text--lowercased"
          label="Username"
          name="userName"
          onInputChanged={ this._handleInputChange }
          returnFullInputEvent={ true } />
        <MainInput
          value={ userPwd }
          inputClass="u-text--lowercased"
          label="Password"
          name="userPwd"
          inputType="password"
          onInputChanged={ this._handleInputChange }
          returnFullInputEvent={ true } />
        <ConfirmationButtons
          onCancel={ this._onCancel(handleCanceled) }
          onConfirm={ this._onFinalConfirm(handleConfirmed, selectedPatientId, userName, userPwd, reasonDescription) }
          confirmLabel="Confirm"
          cancelDisabled={ loading }
          confirmDisabled={ isNilOrEmpty(reasonDescription) || isNilOrEmpty(userName) || isNilOrEmpty(userPwd) || loading }
        />
      </div>
    )
  }

  _handleReasonChange = (newReason) => {
    this.setState({ reasonDescription: newReason })
  }

  _handleInputChange = (event) => {
    const { target } = event
    this.setState({ [target.name]: target.value })
  }

  _onFirstConfirm = () => {
    this.setState({ showSecondConfirmationForm: true })
  }

  _onFinalConfirm = (callback, selectedPatientId, userName, userPwd, reason) => () => {
    const newPatientId = selectedPatientId.value
    try {
      callback({
        newPatientId,
        userName,
        userPwd,
        reason,
      })
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _setAvailablePatientIds = (patientIds) => {
    const patientIdOptions = patientIds.map(patient => ({
      value: patient.id,
      label: patient.id,
    }))

    const selectedPatientId = is(Array, patientIds) && equals(patientIds.length, 1) ? patientIdOptions[0] : null

    this.setState({
      patientIdOptions,
      selectedPatientId,
    })
  }

  _selectedPatientIdChanged = (selectedPatientId) => {
    this.setState({ selectedPatientId })
  }

  _onCancel = cb => () => {
    try {
      cb()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }
}

ChangePatientIdForm.propTypes = {
  availablePatientIds: PropTypes.array.isRequired,
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  loading: PropTypes.object.isRequired,
  errors: PropTypes.object,
}

ChangePatientIdForm.defaultProps = { errors: null }

export default ChangePatientIdForm
