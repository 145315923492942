import { call, put } from 'redux-saga/effects'
import { is, isEmpty, equals } from 'ramda'
import { isNilOrEmpty, findByProp } from 'ramdasauce'
import { push, replace } from 'react-router-redux'

import addTitleToErrorObject from '../utils/ErrorHelper'
import { extractStudiesFromContextResponse } from '../utils/DataHelper'

import ContextActions from '../redux/ContextRedux'
import SetActions from '../redux/SetRedux'

import queries from '../config/ApiConfig'

function findObjectFromArrayById(arrayOfObjects, idToMatch) {
  return is(Array, arrayOfObjects) && !isEmpty(arrayOfObjects) && findByProp('id', idToMatch, arrayOfObjects)
}

// eslint-disable-next-line import/prefer-default-export
export function* fetchContext(api, action) {
  const { isStudyAgnostic, navigateHome } = action
  const { ok, data } = yield call(api.get, queries.Context(), { includeDetails: true })
  const context = data
  if (ok) {
    yield put(ContextActions.fetchContextSuccess(context))

    // parse url for later use
    const urlParameters = window.location.pathname.split('/')
    const indexOfStudy = urlParameters.indexOf('study')
    const hasStudyInUrl = indexOfStudy !== -1 && indexOfStudy < 2 && urlParameters.length > 1 // obscure check to know if it's even possible a studyId is in the url
    // check for available studies and set single study from array or matching study from url
    const availableStudies = extractStudiesFromContextResponse(context)
    // if studies are available for the user, go fishing for single study from array or matching study from url
    if (is(Array, availableStudies) && !isEmpty(availableStudies)) {
      yield put(SetActions.setStudies(availableStudies))
      // start fishing
      let matchingStudy = null
      // if the url has a matching study in in the url, extract the studyId and check if the study is actually available to the user, and if so fetch the data for that study
      const studyIdFromUrl = urlParameters[indexOfStudy + 1]
      if (hasStudyInUrl) {
        matchingStudy = findObjectFromArrayById(availableStudies, studyIdFromUrl)
      }

      // if there's no matching study in the url but there's only one study available
      if (isNilOrEmpty(matchingStudy) && equals(availableStudies.length, 1)) {
        [matchingStudy] = availableStudies

        // if study is not already in url, push it in the browser/navigation stack
        if (!hasStudyInUrl || !equals(matchingStudy.id, studyIdFromUrl)) {
          yield put(push(`/study/${matchingStudy.id}`))
        }
      }

      // if a study matches from url, or only one is available, set that as the selected study
      if (!isNilOrEmpty(matchingStudy)) {
        // yield put(SetActions.setStudy(matchingStudy))
        yield put(SetActions.setStudy(matchingStudy, isStudyAgnostic, navigateHome))
        // study from url doesn't match meaning the user typed in a wrong study or has no access to that study, so redirect the user
      } else if (hasStudyInUrl) {
        yield put(replace('/'))
      }

      // if the url has a study "selected" but no studies are available to the user, redirect to root
    } else if (hasStudyInUrl) {
      yield put(replace('/'))
    }
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading myUCB Insights failed`)
    yield put(ContextActions.fetchContextFailure(errorObject))
    // redirect user to root by default, just in case they're on a child page and they wouldn't view the error
    yield put(replace('/'))
  }
}
