import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isNil, isEmpty, prop, sortBy } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'

import { ICON_LOCATION_RIGHT, ICON_ARROW_LEFT, ICON_ARROW_RIGHT } from '../../config/constants'

import sortById from '../../utils/SortingHelper'

import MainButtonGroup from '../buttons/MainButtonGroup'
import MainButton from '../buttons/MainButton'
import RadioButton from '../buttons/RadioButton'

import ErrorHandler from '../error/ErrorHandler'
import HintLabel from '../HintLabel'

import VisitFormsTable from '../tables/VisitFormsTable'
import VisitFormsTableItem from '../tables/VisitFormsTableItem'
import LoadingBox from '../loading/LoadingBox'


class AddUnscheduledVisitForEngageForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentScreenShown: 0,
      plannedUnplannedChoice: 0, // 0 = nothing, 1 = planned, 2 = unplanned
      selectedForms: [],
      selectedTemplate: null,
    }
  }

  render() {
    const { error, loading, handleCanceled, handleConfirmed, availableTemplates, availableForms, busyFetchingVisitTemplates, busyFetchingVisitForms } = this.props
    const { currentScreenShown, plannedUnplannedChoice, selectedForms, selectedTemplate } = this.state

    const showSelectPlannedUnplanned = currentScreenShown === 0 // first screen: have the user choose between a "planned" and an "unplanned" unscheduled visit
    const showSelectTemplate = currentScreenShown === 1 // alternative second screen: choose template (if user chose "planned")
    const showSelectCOAs = currentScreenShown === 2 // second screen: choose separate eCOAs (if user chose "unplanned")
    const showSummary = currentScreenShown === 3 // third screen: show summary to confirm

    const hasTemplates = !isNilOrEmpty(availableTemplates)
    const hasForms = !isNilOrEmpty(availableForms)
    const hasErrors = error.find(err => !isNil(err))

    return (
      <div>
        { hasErrors && this._renderError(error) }
        { !hasErrors && showSelectPlannedUnplanned && this._renderSelectPlannedUnplannedScreen(plannedUnplannedChoice) }
        { showSelectTemplate && busyFetchingVisitTemplates && this._renderLoading("templates") }
        { showSelectTemplate && !busyFetchingVisitTemplates && !hasTemplates && this._renderNoTemplatesOrForms("templates") }
        { showSelectTemplate && !busyFetchingVisitTemplates && hasTemplates && this._renderSelectTemplateScreen(availableTemplates, selectedTemplate) }
        { showSelectCOAs && busyFetchingVisitForms && this._renderLoading("forms") }
        { showSelectCOAs && !busyFetchingVisitForms && !hasForms && this._renderNoTemplatesOrForms("forms") }
        { showSelectCOAs && !busyFetchingVisitForms && hasForms && this._renderSelectCOAsScreen(availableForms, selectedForms) }
        { showSummary && this._renderSummaryScreen(plannedUnplannedChoice, selectedTemplate, selectedForms) }
        { this._renderBackNextButtons(currentScreenShown, loading, handleCanceled, handleConfirmed, plannedUnplannedChoice, selectedTemplate, selectedForms) }
      </div>
    )
  }

  _renderNoTemplatesOrForms = formsOrTemplates => (
    <HintLabel
      size={ 16 }
      hintClass="u-margin--top">
      { `No visit ${formsOrTemplates} available.` }
    </HintLabel>
  )


  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  _renderLoading = formsOrTemplates => (
    <LoadingBox
      size={ 16 }
      message={ `Busy loading visit ${formsOrTemplates}` } />
  )

  _renderBackNextButtons = (currentScreenShown, loading, handleCanceled, handleConfirmed, plannedUnplannedChoice, selectedTemplate, selectedForms) => (
    <MainButtonGroup>
      <MainButton
        label={ this._isOnFirstScreen(currentScreenShown) ? "Cancel" : "Back" }
        handleClick={ this._isOnFirstScreen(currentScreenShown) ? this._onCancel(handleCanceled) : this._goToPreviousScreen(currentScreenShown, plannedUnplannedChoice) }
        icon={ this._isOnFirstScreen(currentScreenShown) ? null : { name: ICON_ARROW_LEFT } }
        id={ this._isOnFirstScreen(currentScreenShown) ? "button-cancel" : "button-previous" }
        buttonClass="inverted-blue" />
      <MainButton
        label={ this._isOnLastScreen(currentScreenShown) ? "Confirm" : "Next" }
        handleClick={ this._isOnLastScreen(currentScreenShown) ? (plannedUnplannedChoice === 1 ? this._onConfirmSummary(handleConfirmed, selectedTemplate, plannedUnplannedChoice) : this._onConfirmSummary(handleConfirmed, selectedForms)) : this._goToNextScreen(currentScreenShown, plannedUnplannedChoice) }
        icon={ !this._isOnLastScreen(currentScreenShown) ? { name: ICON_ARROW_RIGHT, location: ICON_LOCATION_RIGHT } : null }
        id={ this._isOnLastScreen(currentScreenShown) ? "button-confirm" : "button-next" }
        buttonClass="blue"
        isDisabled={ loading || (this._isOnFirstScreen(currentScreenShown) && plannedUnplannedChoice === 0) || (currentScreenShown === 1 && plannedUnplannedChoice === 1 && isNil(selectedTemplate)) || (currentScreenShown === 2 && plannedUnplannedChoice === 2 && isEmpty(selectedForms)) }
        iconLocation={ ICON_LOCATION_RIGHT } />
    </MainButtonGroup>
  )

  _isOnFirstScreen = currentScreenShown => currentScreenShown === 0

  _isOnLastScreen = currentScreenShown => currentScreenShown === 3

  _goToPreviousScreen = (currentScreenShown, plannedUnplannedChoice) => () => {
    if (currentScreenShown === 3) {
      if (plannedUnplannedChoice === 1) { // user selected planned
        this.setState({ currentScreenShown: 1 })
      } else { // user selected unplanned
        this.setState({ currentScreenShown: 2 })
      }
    } else {
      this.setState({ currentScreenShown: 0 })
    }
  }

  _goToNextScreen = (currentScreenShown, plannedUnplannedChoice) => () => {
    if (currentScreenShown === 0) {
      if (plannedUnplannedChoice === 1) { // user selected planned
        this.setState({ currentScreenShown: 1 })
      } else { // user selected unplanned
        this.setState({ currentScreenShown: 2 })
      }
    } else {
      this.setState({ currentScreenShown: 3 })
    }
  }

  // SELECT PLANNED/UNPLANNED
  _renderSelectPlannedUnplannedScreen = plannedUnplannedChoice => (
    <div>
      <HintLabel
        size={ 16 }
        hintClass="main-input-title">
        Please select which type of unscheduled visit you wish to create:
      </HintLabel>
      <MainButtonGroup>
        <MainButton
          label="Planned"
          buttonClass={ plannedUnplannedChoice === 1 ? "green" : "inverted-green" }
          handleClick={ this._choseTypeOfVisit(1) }
          id="button-planned-choice" />
        <MainButton
          label="Unplanned"
          buttonClass={ plannedUnplannedChoice === 2 ? "bordeaux" : "inverted-bordeaux" }
          handleClick={ this._choseTypeOfVisit(2) }
          id="button-unplanned-choice" />
      </MainButtonGroup>
    </div>
  )

  _choseTypeOfVisit = type => () => {
    this.setState({
      plannedUnplannedChoice: type,
      selectedForms: [],
      selectedTemplate: null,
    })
    // TODO: set focus to "next" button
  }

  // SELECT COAs
  _renderSelectCOAsScreen = (availableForms, selectedForms) => (
    <VisitFormsTable
      showOrder={ true }
      hasCheckbox={ true }>
      { availableForms.map(availableForm => (
        <VisitFormsTableItem
          key={ availableForm.reference }
          showOrder={ true }
          canSelect={ true }
          onSelect={ this._changeSelectedForms(selectedForms, availableForm) }
          isSelected={ selectedForms.indexOf(availableForm) !== -1 }
          order={ availableForm.order }
          name={ isNilOrEmpty(availableForm.name) ? `<${availableForm.reference}>` : availableForm.name }
          type={ availableForm.formType }
          duration={ availableForm.duration } />
      )) }
    </VisitFormsTable>
  )

  _changeSelectedForms = (previouslySelectedForms, selectedForm) => () => {
    const indexOfFormToRemove = previouslySelectedForms.indexOf(selectedForm)
    const newSelectedForms = [...previouslySelectedForms]
    if (indexOfFormToRemove !== -1) {
      newSelectedForms.splice(indexOfFormToRemove, 1)
    } else {
      newSelectedForms.push(selectedForm)
    }
    this.setState({ selectedForms: sortById(newSelectedForms) })
  }

  // SELECT TEMPLATE
  _renderLoadingVisitTemplates = () => (
    <LoadingBox
      size={ 16 }
      message="Busy loading visit templates" />
  )

  _renderSelectTemplateScreen = (availableTemplates, selectedTemplate) => { // eslint-disable-line
    return (
      <div className="radio-container">
        { availableTemplates.map(template => (
          <RadioButton
            key={ template.reference }
            name="visit-template"
            value={ template.reference }
            checked={ !isNil(selectedTemplate) && selectedTemplate.reference === template.reference }
            handleRadioSelected={ this._changeSelectedTemplate(template) }>
            <span>
              { template.reference }
            </span>
            { !isNil(selectedTemplate) && selectedTemplate.reference === template.reference && this._renderTemplateDetails(template) }
          </RadioButton>
        )) }
      </div>
    )
  }

  _renderTemplateDetails = template => (
    <VisitFormsTable
      negativeColor={ true }>
      { template.forms.map(form => (
        <VisitFormsTableItem
          key={ form.reference }
          name={ form.name || form.reference }
          type={ form.formType }
          duration={ form.duration } />
      )) }
    </VisitFormsTable>
  )

  _changeSelectedTemplate = template => () => {
    this.setState({ selectedTemplate: template })
  }

  // SUMMARY
  _renderSummaryScreen = (plannedUnplannedChoice, selectedTemplate, selectedForms) => (
    <div>
      <HintLabel
        size={ 16 }>
        Selected
        { plannedUnplannedChoice === 1 ? " a" : " an" }
        <strong>
          { plannedUnplannedChoice === 1 ? " planned " : " unplanned " }
        </strong>
        visit with the following
        <strong>
          { plannedUnplannedChoice === 1 ? " template" : " form(s)" }
        </strong>
        :
      </HintLabel>
      { plannedUnplannedChoice === 1 ? this._renderSummaryDetails(selectedTemplate.forms, selectedTemplate.reference) : this._renderSummaryDetails(selectedForms) }
    </div>
  )

  _renderSummaryDetails = (forms, title) => (
    <div>
      <VisitFormsTable>
        { sortBy(prop('order'), forms).map(form => (
          <VisitFormsTableItem
            key={ form.reference }
            name={ form.name || form.reference }
            type={ form.formType }
            duration={ form.duration } />
        )) }
      </VisitFormsTable>
      { !isNilOrEmpty(title) && (
        <div style={ { textAlign: 'right', marginTop: '5px' } }>
          { `(${title})` }
        </div>
      ) }
    </div>
  )

  _onCancel = callback => () => {
    try {
      callback()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _onConfirmSummary = (callback, visitDetails, plannedUnplannedChoice) => () => {
    try {
      callback(visitDetails, plannedUnplannedChoice === 1)
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }
}

AddUnscheduledVisitForEngageForm.propTypes = {
  error: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  availableTemplates: PropTypes.array,
  availableForms: PropTypes.array,
  busyFetchingVisitTemplates: PropTypes.bool.isRequired,
  busyFetchingVisitForms: PropTypes.bool.isRequired,
}

AddUnscheduledVisitForEngageForm.defaultProps = {
  error: [],
  availableTemplates: [],
  availableForms: [],
}

export default AddUnscheduledVisitForEngageForm
