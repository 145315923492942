import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

function PatientPDIContentTableRow(props) {
  const { tableContent } = props
  return (
    <tr>
      { tableContent.map(content => (
        <td key={ shortid.generate() }>
          { content }
        </td>
      ))}
    </tr>
  )
}

PatientPDIContentTableRow.propTypes = { tableContent: PropTypes.array }

PatientPDIContentTableRow.defaultProps = { tableContent: [] }

export default PatientPDIContentTableRow
