import { put } from 'redux-saga/effects'
import { is, isEmpty, equals } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'
import { replace, push } from 'react-router-redux'

import SetActions from '../redux/SetRedux'
import PatientActions from '../redux/PatientRedux'
import VisitActions from '../redux/VisitRedux'
import PdiActions from '../redux/PdiRedux'

export function* extraLogicToSetStudy(action) {
  const { study, isStudyAgnostic, navigateHome } = action
  // extract studyId
  const studyId = study.id
  // check for possible sites under that study
  const availableSites = study.sites
  // parse url for later use, and see if a certain site was already "selected"
  const urlParameters = window.location.pathname.split('/')
  const indexOfSite = urlParameters.indexOf('site')
  const hasSiteInUrl = indexOfSite !== -1 && indexOfSite < 4 && urlParameters.length > 3 // obscure check to know if it's even possible a siteId is in the url

  // if the study has sites, go fishing for a single site or a site from the url
  if (is(Array, availableSites) && !isEmpty(availableSites)) {
    // if the url has a matching site in in the url, extract the siteId and fetch the data for that site
    const siteIdFromUrl = urlParameters[indexOfSite + 1]
    // check if siteId matches any of the available sites under that study
    const matchingSite = (hasSiteInUrl && availableSites.find(site => site.id === siteIdFromUrl))
      // or if it doesn't match, but there's only one available site, immediately select that one
      || (equals(availableSites.length, 1) && availableSites[0])

    // if a site matches
    if (matchingSite && !isNilOrEmpty(matchingSite)) {
      yield put(SetActions.setSite(studyId, matchingSite, isStudyAgnostic, navigateHome))
      // if site is not already in url, push it in the browser/navigation stack
      if (!hasSiteInUrl || !equals(matchingSite.id, siteIdFromUrl)) {
        yield put(push(`/study/${studyId}/site/${matchingSite.id}`))
      }
      // site does not match, meaning the user typed in a wrong site or has no access to that site. So redirect the user
    } else if (hasSiteInUrl) {
      yield put(replace(`/study/${studyId}`))
    }
    // if the url has a site "selected" but no sites are available to the user, redirect to study page
  } else if (hasSiteInUrl) {
    yield put(replace(`/study/${studyId}`))
  }
}

export function* extraLogicToSetSite(action) {
  const { studyId, site, isStudyAgnostic, navigateHome } = action
  // dispatch success of fetching patients to set patients in the sidebar
  yield put(PatientActions.fetchPatientsSuccess(site.patients))

  // parse url for later use
  const urlParameters = window.location.pathname.split('/')
  const indexOfPatient = urlParameters.indexOf('patient')
  const hasPatientInUrl = indexOfPatient !== -1 && indexOfPatient < 6 && urlParameters.length > 5 // obscure check to know if it's even possible a patientId is in the url
  // check for available patients under that study
  const availablePatients = site.patients

  // if patients are available for the user, go fishing for a single patient or a matching patient from the url
  if (is(Array, availablePatients) && !isEmpty(availablePatients)) {
    // if the url has a matching patient in in the url, extract the patientId and fetch the data for that patient
    const patientIdFromUrl = urlParameters[indexOfPatient + 1]
    const matchingPatient = (hasPatientInUrl && availablePatients.find(patient => patient.id === patientIdFromUrl))
      || (equals(availablePatients.length, 1) && availablePatients[0])

    // if a patient matches
    if (matchingPatient && !isNilOrEmpty(matchingPatient)) {
      const patientId = matchingPatient.id
      yield put(PatientActions.fetchPatient(studyId, patientId, true)) // true = include details
      yield put(VisitActions.fetchVisits(studyId, patientId, true)) // true = include details
      yield put(PdiActions.fetchAllPdis(studyId, patientId))
      // if patient is not already in url, push it in the browser/navigation stack
      if ((!hasPatientInUrl || !equals(matchingPatient.id, patientIdFromUrl)) && !navigateHome) {
        yield put(push(`/study/${studyId}/site/${site.id}/patient/${patientId}`))
      }
    } else if (hasPatientInUrl) {
      // patient does not match, meaning the user typed in a wrong patient or has no access to that patient. So redirect the user. the if-part "hasPatientInUrl" is there because otherwise it would also redirect/push the user, even though they're already on that url
      yield put(replace(`/study/${studyId}/site/${site.id}`))
    }
    // if the url has a site "selected" but no sites are available to the user, redirect to the site page
  } else if (hasPatientInUrl) {
    yield put(replace(`/study/${studyId}/site/${site.id}`))
  }
}
