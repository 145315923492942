import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { propEq, findIndex } from 'ramda'

import sortById from '../utils/SortingHelper'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  // region patient
  fetchPatient: ['studyId', 'patientId', 'includeDetails'],
  fetchPatientSuccess: ['patient'],
  fetchPatientFailure: ['error'],
  // endregion
  // region patients
  fetchPatients: ['studyId', 'countryId', 'siteId', 'includeDetails'],
  fetchPatientsSuccess: ['patients'],
  fetchPatientsFailure: ['error'],
  // endregion
  // region available patient ids
  fetchAvailablePatientIds: ['studyId', 'countryId', 'siteId'],
  fetchAvailablePatientIdsSuccess: ['availablePatientIds'],
  fetchAvailablePatientIdsFailure: ['error'],
  // endregion
  // region add patient
  addPatient: ['studyId', 'countryId', 'siteId', 'patientId', 'studyType', 'newPatient'],
  addPatientSuccess: ['activatedPatient'],
  addPatientFailure: ['error'],
  resetAddPatientError: null,
  // endregion
  // region change patient id
  changePatientId: ['studyId', 'siteId', 'countryId', 'newPatientId', 'patientId', 'credentials', 'reason'],
  changePatientIdSuccess: ['newPatientId', 'oldPatientId'],
  changePatientIdFailure: ['error'],
  resetChangePatientIdError: null,
  // endregion
  // region change device
  changeDevice: ['patientWithChangedDevice'],
  changeDeviceSuccess: ['activatedPatient'],
  changeDeviceFailure: ['error'],
  resetChangeDeviceError: null,
  // endregion
  // region change language
  changeLanguage: ['studyId', 'patientId', 'newPrimaryLanguage'],
  changeLanguageSuccess: ['newPrimaryLanguage'],
  changeLanguageFailure: ['error'],
  resetChangeLanguageError: null,
  // endregion
  // region save screening end date
  saveScreeningEndDate: ['studyId', 'patientId', 'screeningEndDate'],
  saveScreeningEndDateSuccess: ['updatedPatient'],
  saveScreeningEndDateFailure: ['error'],
  // endregion
  // region save baseline start date
  saveBaselineStartDate: ['studyId', 'patientId', 'baselineStartDate'],
  saveBaselineStartDateSuccess: ['updatedPatient'],
  saveBaselineStartDateFailure: ['error'],
  // endregion
  requestResetCode: ['studyId', 'patientId', 'credentials'],
  requestResetCodeSuccess: ['resetCode'],
  requestResetCodeFailure: ['error'],
  resetRequestResetCodeError: null,
  // endregion
})

export const PatientTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  // region unsorted
  activatedPatient: null,
  patientId: null,
  // endregion
  // region patient
  patient: null,
  fetchPatientError: null,
  busyFetchingPatient: false,
  // endregion
  // region patients
  patientList: [],
  fetchPatientsError: null,
  busyFetchingPatients: false,
  // endregion
  // region available patient ids
  availablePatientIds: [],
  fetchAvailablePatientIdsError: null,
  busyFetchingAvailablePatientIds: false,
  // endregion
  // region add patient
  newPatient: null,
  addPatientError: null,
  busyAddingPatient: false,
  // endregion
  // region change patient
  newPatientId: null,
  changePatientIdError: null,
  busyChangingPatientId: false,
  // endregion
  // region change device
  patientWithChangedDevice: null,
  changeDeviceError: null,
  busyChangingDevice: false,
  // endregion
  // region change language
  newPrimaryLanguage: null,
  changeLanguageError: null,
  busyChangingLanguage: false,
  // endregion
  // region save screening end date
  screeningEndDate: null,
  saveScreeningEndDateError: null,
  busySavingScreeningEndDate: false,
  // endregion
  // region save baseline start date
  baselineStartDate: null,
  saveBaselineStartDateError: null,
  busySavingBaselineStartDate: false,
  // endregion
  // region request reset code
  newResetCode: null,
  requestResetCodeError: null,
  busyRequestingResetCode: false,
  // endregion
})

/* ------------- Reducers ------------- */

// region fetch patient
export const fetchPatient = (state, { patientId }) => state.merge({
  busyFetchingPatient: true,
  patientId,
  patient: null,
  fetchPatientError: null,
})
export const fetchPatientSuccess = (state, { patient }) => state.merge({
  busyFetchingPatient: false,
  patient,
  patientId: null,
})
export const fetchPatientFailure = (state, { error }) => state.merge({
  busyFetchingPatient: false,
  fetchPatientError: error,
})
// endregion
// region fetch patients
export const fetchPatients = state => state.merge({
  busyFetchingPatients: true,
  patientList: [],
  fetchPatientsError: null,
})
export const fetchPatientsSuccess = (state, { patients }) => state.merge({
  busyFetchingPatients: false,
  patientList: sortById(patients),
})
export const fetchPatientsFailure = (state, { error }) => state.merge({
  busyFetchingPatients: false,
  fetchPatientsError: error,
})
// endregion
// region fetch available patient ids
export const fetchAvailablePatientIds = state => state.merge({
  busyFetchingAvailablePatientIds: true,
  availablePatientIds: [],
  fetchAvailablePatientIdsError: null,
})
export const fetchAvailablePatientIdsSuccess = (state, { availablePatientIds }) => state.merge({
  busyFetchingAvailablePatientIds: false,
  availablePatientIds,
})
export const fetchAvailablePatientIdsFailure = (state, { error }) => state.merge({
  busyFetchingAvailablePatientIds: false,
  fetchAvailablePatientIdsError: error,
})
// endregion
// region Add patient
export const addPatient = (state, { newPatient }) => state.merge({
  busyAddingPatient: true,
  newPatient,
  activatedPatient: null,
  addPatientError: null,
})
export const addPatientSuccess = (state, { activatedPatient }) => state.merge({
  busyAddingPatient: false,
  newPatient: null,
  activatedPatient,
  patientList: sortById([...state.patientList, { id: activatedPatient.id }]),
})
export const addPatientFailure = (state, { error }) => state.merge({
  busyAddingPatient: false,
  addPatientError: error,
})
export const resetAddPatientError = state => state.merge({ addPatientError: null })
// endregion
// region change patient id
export const changePatientId = (state, { newPatientId }) => state.merge({
  busyChangingPatientId: true,
  newPatientId,
  changePatientIdError: null,
})
export const changePatientIdSuccess = (state, { newPatientId, oldPatientId }) => state.merge({
  busyChangingPatientId: false,
  newPatientId: null,
  patientList: sortById([...state.patientList.map(patient => (patient.id === oldPatientId ? { ...patient, id: newPatientId } : patient))]),
  availablePatientIds: sortById([
    ...state.availablePatientIds.slice(0, findIndex(propEq('id', newPatientId), state.availablePatientIds)), // everything before new patient id
    ...state.availablePatientIds.slice(findIndex(propEq('id', newPatientId), state.availablePatientIds) + 1), // everything after new patient id
  ]),
  patient: {
    ...state.patient,
    patientId: newPatientId,
  },
})
export const changePatientIdFailure = (state, { error }) => state.merge({
  busyChangingPatientId: false,
  changePatientIdError: error,
})
export const resetChangePatientIdError = state => state.merge({ changePatientIdError: null })
// endregion
// region change device
export const changeDevice = (state, { patientWithChangedDevice }) => state.merge({
  busyChangingDevice: true,
  patientWithChangedDevice,
  activatedPatient: null,
  changeDeviceError: null,
})
export const changeDeviceSuccess = (state, { activatedPatient }) => state.merge({
  busyChangingDevice: false,
  patientWithChangedDevice: null,
  activatedPatient,
  patient: {
    ...state.patient,
    _embedded: {
      ...state.patient._embedded,
      device: {
        ...state.patient._embedded.device,
        applicationId: activatedPatient._embedded.result.applicationId,
      },
    },
  },
})
export const changeDeviceFailure = (state, { error }) => state.merge({
  busyChangingDevice: false,
  changeDeviceError: error,
})
export const resetChangeDeviceError = state => state.merge({ changeDeviceError: null })
// endregion
// region change language
export const changeLanguage = (state, { newPrimaryLanguage }) => state.merge({
  busyChangingLanguage: true,
  newPrimaryLanguage,
  changeLanguageError: null,
})
export const changeLanguageSuccess = (state, { newPrimaryLanguage }) => state.merge({
  busyChangingLanguage: false,
  newPrimaryLanguage: null,
  patient: {
    ...state.patient,
    primaryLanguage: newPrimaryLanguage,
  },
})
export const changeLanguageFailure = (state, { error }) => state.merge({
  busyChangingLanguage: false,
  changeLanguageError: error,
})
export const resetChangeLanguageError = state => state.merge({ changeLanguageError: null })
// endregion
// region save screeing end date
export const saveScreeningEndDate = (state, { screeningEndDate }) => state.merge({
  busySavingScreeningEndDate: true,
  screeningEndDate,
  saveScreeningEndDateError: null,
})
export const saveScreeningEndDateSuccess = (state, { updatedPatient }) => state.merge({
  busySavingScreeningEndDate: false,
  patient: updatedPatient,
  screeningEndDate: null,
})
export const saveScreeningEndDateFailure = (state, { error }) => state.merge({
  busySavingScreeningEndDate: false,
  saveScreeningEndDateError: error,
})
// endregion
// region save baseline start date
export const saveBaselineStartDate = (state, { baselineStartDate }) => state.merge({
  busySavingBaselineStartDate: true,
  baselineStartDate,
  saveBaselineStartDateError: null,
})
export const saveBaselineStartDateSuccess = (state, { updatedPatient }) => state.merge({
  busySavingBaselineStartDate: false,
  patient: updatedPatient,
  baselineStartDate: null,
})
export const saveBaselineStartDateFailure = (state, { error }) => state.merge({
  busySavingBaselineStartDate: false,
  saveBaselineStartDateError: error,
})
// endregion
// region request reset code
export const requestResetCode = state => state.merge({
  busyRequestingResetCode: true,
  newResetCode: null,
  requestResetCodeError: null,
})
export const requestResetCodeSuccess = (state, { resetCode }) => state.merge({
  busyRequestingResetCode: false,
  newResetCode: resetCode,
})
export const requestResetCodeFailure = (state, { error }) => state.merge({
  busyRequestingResetCode: false,
  requestResetCodeError: error,
})
export const resetRequestResetCodeError = state => state.merge({ requestResetCodeError: null })
// endregion
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  // region fetch patient
  [Types.FETCH_PATIENT]: fetchPatient,
  [Types.FETCH_PATIENT_SUCCESS]: fetchPatientSuccess,
  [Types.FETCH_PATIENT_FAILURE]: fetchPatientFailure,
  // endregion
  // region fetch patients
  [Types.FETCH_PATIENTS]: fetchPatients,
  [Types.FETCH_PATIENTS_SUCCESS]: fetchPatientsSuccess,
  [Types.FETCH_PATIENTS_FAILURE]: fetchPatientsFailure,
  // endregion
  // region fetch available patient ids
  [Types.FETCH_AVAILABLE_PATIENT_IDS]: fetchAvailablePatientIds,
  [Types.FETCH_AVAILABLE_PATIENT_IDS_SUCCESS]: fetchAvailablePatientIdsSuccess,
  [Types.FETCH_AVAILABLE_PATIENT_IDS_FAILURE]: fetchAvailablePatientIdsFailure,
  // endregion
  // region add patient
  [Types.ADD_PATIENT]: addPatient,
  [Types.ADD_PATIENT_SUCCESS]: addPatientSuccess,
  [Types.ADD_PATIENT_FAILURE]: addPatientFailure,
  [Types.RESET_ADD_PATIENT_ERROR]: resetAddPatientError,
  // endregion
  // region change patient id
  [Types.CHANGE_PATIENT_ID]: changePatientId,
  [Types.CHANGE_PATIENT_ID_SUCCESS]: changePatientIdSuccess,
  [Types.CHANGE_PATIENT_ID_FAILURE]: changePatientIdFailure,
  [Types.RESET_CHANGE_PATIENT_ID_ERROR]: resetChangePatientIdError,
  // endregion
  // region change device
  [Types.CHANGE_DEVICE]: changeDevice,
  [Types.CHANGE_DEVICE_SUCCESS]: changeDeviceSuccess,
  [Types.CHANGE_DEVICE_FAILURE]: changeDeviceFailure,
  [Types.RESET_CHANGE_DEVICE_ERROR]: resetChangeDeviceError,
  // endregion
  // region change language
  [Types.CHANGE_LANGUAGE]: changeLanguage,
  [Types.CHANGE_LANGUAGE_SUCCESS]: changeLanguageSuccess,
  [Types.CHANGE_LANGUAGE_FAILURE]: changeLanguageFailure,
  [Types.RESET_CHANGE_LANGUAGE_ERROR]: resetChangeLanguageError,
  // endregion
  // region save screening end date
  [Types.SAVE_SCREENING_END_DATE]: saveScreeningEndDate,
  [Types.SAVE_SCREENING_END_DATE_SUCCESS]: saveScreeningEndDateSuccess,
  [Types.SAVE_SCREENING_END_DATE_FAILURE]: saveScreeningEndDateFailure,
  // endregion
  // region save baseline start date
  [Types.SAVE_BASELINE_START_DATE]: saveBaselineStartDate,
  [Types.SAVE_BASELINE_START_DATE_SUCCESS]: saveBaselineStartDateSuccess,
  [Types.SAVE_BASELINE_START_DATE_FAILURE]: saveBaselineStartDateFailure,
  // region request reset code
  [Types.REQUEST_RESET_CODE]: requestResetCode,
  [Types.REQUEST_RESET_CODE_SUCCESS]: requestResetCodeSuccess,
  [Types.REQUEST_RESET_CODE_FAILURE]: requestResetCodeFailure,
  [Types.RESET_REQUEST_RESET_CODE_ERROR]: resetRequestResetCodeError,
  // endregion
})
