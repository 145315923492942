/* eslint-disable react/style-prop-object */
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isNil } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'

import DailyTasksActions from '../../redux/DailyTasksRedux'
import PatientDailyTasksTable from '../../components/tables/PatientDailyTasksTable'
import PatientStudyAssessmentsTable from '../../components/tables/PatientStudyAssessmentsTable'
import { getDailyTasksEmptyText } from '../../utils/StudyHelper'
import HintLabel from '../../components/HintLabel'
import ErrorHandler from '../../components/error/ErrorHandler'
import LoadingBox from '../../components/loading/LoadingBox'


const _renderDailyTasks = (dailyTasks, currentSiteDate) => (
  <PatientDailyTasksTable dailyTasks={ dailyTasks } currentSiteDate={ currentSiteDate } />
)

const _renderAssessments = assessments => (
  <PatientStudyAssessmentsTable studyAssessments={ assessments } />
)

const _renderEmptyLabel = resources => (
  <HintLabel
    size={ 20 }
    hintClass="u-margin--top">
    { getDailyTasksEmptyText(resources) }
  </HintLabel>
)

const _renderLoading = () => (
  <LoadingBox
    size={ 20 }
    message={ `Busy loading Patient's Daily Tasks` } />
)

const _renderError = error => (
  <ErrorHandler error={ error } />
)

function PatientTabDailyTasks(props) {
  const {
    match, fetchDailyTasks, dailyTasks, assessments, currentSiteDate, resources,
    busyFetchingDailyTasks, fetchDailyTasksError,
  } = props
  // eslint-disable-next-line camelcase
  const { study_id, patient_id } = match.params

  useEffect(() => {
    fetchDailyTasks(study_id, patient_id)
  }, [])

  const hasError = !isNil(fetchDailyTasksError)
  const hasDailyTasks = !isNilOrEmpty(dailyTasks)
  const hasAssessments = !isNilOrEmpty(assessments)

  return (
    <div>
      { busyFetchingDailyTasks && _renderLoading() }
      { !busyFetchingDailyTasks && hasError && _renderError(fetchDailyTasksError) }
      { !busyFetchingDailyTasks && !hasError && !hasDailyTasks && !hasAssessments && _renderEmptyLabel(resources) }
      { !busyFetchingDailyTasks && !hasError && hasDailyTasks && _renderDailyTasks(dailyTasks, currentSiteDate) }
      { !busyFetchingDailyTasks && !hasError && hasAssessments && _renderAssessments(assessments) }
    </div>
  )
}


const mapStateToProps = state => ({
  dailyTasks: state.dailyTasks.dailyTasks,
  assessments: state.dailyTasks.assessments,
  currentSiteDate: state.dailyTasks.currentSiteDate,
  resources: state.set.study.resources,
  busyFetchingDailyTasks: state.dailyTasks.busyFetchingDailyTasks,
  fetchDailyTasksError: state.dailyTasks.fetchDailyTasksError,
})
const mapDispatchToProps = dispatch => ({ fetchDailyTasks: (studyId, patientId) => dispatch(DailyTasksActions.fetchDailyTasks(studyId, patientId)) })

PatientTabDailyTasks.propTypes = {
  fetchDailyTasks: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  dailyTasks: PropTypes.array.isRequired,
  assessments: PropTypes.array.isRequired,
  currentSiteDate: PropTypes.string,
  resources: PropTypes.object,
  busyFetchingDailyTasks: PropTypes.bool.isRequired,
  fetchDailyTasksError: PropTypes.object,

}

PatientTabDailyTasks.defaultProps = {
  resources: null,
  currentSiteDate: "",
  fetchDailyTasksError: null,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientTabDailyTasks))
