import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { connect } from 'react-redux'
import { equals, is, isEmpty, isNil } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'

import { isVisitUnscheduled, isScreeningVisit, isVisitUnlocked, isVisitCanceled, isVisitSkipped, isFirstVisit } from '../../utils/VisitHelper'
import { isBeforeToday } from '../../utils/MomentHelper'

class VisitSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeOption: null,
      optionList: [],
    }
  }

  componentDidMount() {
    const { visits, patient, dummyVisit, serverTime } = this.props
    const patientHasScreeningPeriodEnabled = !isNilOrEmpty(patient) && !isNil(patient.startScreening)
    const upcomingVisit = !isNilOrEmpty(patient) && !isNil(patient.upcomingVisit) && patient.upcomingVisit
    this._setOptions(visits, patientHasScreeningPeriodEnabled, upcomingVisit, !!patient.baseline, dummyVisit, serverTime)
  }

  componentWillReceiveProps(nextProps) {
    const { visits, patient, dummyVisit, serverTime } = this.props
    const { visit } = nextProps
    const patientHasScreeningPeriodEnabled = !isNilOrEmpty(patient) && !isNil(patient.startScreening)
    const upcomingVisit = !isNilOrEmpty(patient) && !isNil(patient.upcomingVisit)
    // console.log(nextProps.dummyVisit)
    if (!equals(nextProps.visits, visits) || !equals(nextProps.dummyVisit, dummyVisit)) {
      this._setOptions(visit, patientHasScreeningPeriodEnabled, upcomingVisit, !!patient.baseline, nextProps.dummyVisit, serverTime)
    }
  }

  render() {
    const { activeOption, optionList } = this.state
    return (
      <div className="visit-selector--container">
        <Select
          className="portal-select-input u-margin--vertical"
          clearable={ false }
          value={ activeOption }
          options={ optionList }
          placeholder="Select visit"
          onChange={ this._onChange }
          id="dropdown-visits" />
      </div>
    )
  }

  _onChange = (option) => {
    const { onChange, visits, patient } = this.props
    const patientHasScreeningPeriodEnabled = !isNilOrEmpty(patient) && !isNil(patient.startScreening)
    if (patientHasScreeningPeriodEnabled && isNilOrEmpty(patient.baseline)) {
      onChange(option)
      this.setState(() => ({ activeOption: option }))
    } else if (visits) {
      // call parent function
      onChange(visits[option.value])
      // set state
      this.setState(() => ({ activeOption: option }))
    }
  }

  _getNextVisit = (visits, index) => {
    let nextScheduledVisit = null
    let counter = index + 1
    while (counter < visits.length) {
      if (isVisitCanceled(visits[counter].status) || isVisitSkipped(visits[counter].status)) {
        counter++
      } else {
        nextScheduledVisit = visits[counter]
        counter = visits.length
      }
    }
    return nextScheduledVisit
  }

  _setOptions(visits, patientHasScreeningPeriodEnabled, upcomingVisit, hasBaseline, dummyVisit, serverTime) {
    if (is(Array, visits) && !isEmpty(visits)) {
      // get possible visits as options
      const visitsAsOptions = this._extractVisitsElligibleAsOptions(visits, patientHasScreeningPeriodEnabled)
      if (patientHasScreeningPeriodEnabled && !hasBaseline) {
        // create dummy baseline visit to hold data if the baseline hasn't been set yet
        const baselineVisit = Object.assign({}, {
          id: 0,
          value: 1,
          status: 0,
          visit: { title: dummyVisit },
        })

        visitsAsOptions.push(this._createVisitOption(baselineVisit, 1))
        this._onChange(visitsAsOptions[0])
      } else if (!isEmpty(visitsAsOptions)) {
        // find the visit in the overal visits list and then match that visit with the visitsAsOptions
        const lastOption = visitsAsOptions[visitsAsOptions.length - 1]
        let newUpcomingVisit = upcomingVisit && upcomingVisit.plannedOn ? upcomingVisit : visits.find(visit => equals(visit.id, upcomingVisit))
        visits.map((visit, index) => {
          const visitIsUnscheduled = isVisitUnscheduled(visit)
          while ((equals(visit.id, newUpcomingVisit && newUpcomingVisit.id) && (visitIsUnscheduled || isBeforeToday(newUpcomingVisit && newUpcomingVisit.plannedOn, serverTime)))) {
            newUpcomingVisit = this._getNextVisit(visits, index)
          }
          return newUpcomingVisit
        })
        if (isNilOrEmpty(newUpcomingVisit)) {
          // automatically select the last visit if there is no upcoming visit
          this._onChange(lastOption)
        } else {
          // console.log(newUpcomingVisit.visit && "VISIT_14" === newUpcomingVisit.visit.title)
          let optionsVisit = visitsAsOptions.find(visit => newUpcomingVisit.visit && visit.label === newUpcomingVisit.visit.title)
          if (isNilOrEmpty(optionsVisit)) {
            // this would mean there is no match between the upcomingvisit and the existing options, which indicates the upcoming visit is still in the screening period
            // we can take the first visit from the options as that would be the first visit where data can be grouped under
            [optionsVisit] = visitsAsOptions
          }
          this._onChange(optionsVisit) // automatically select the upcoming visit
        }
      }

      this.setState({ optionList: visitsAsOptions })
    }
  }

  _extractVisitsElligibleAsOptions = (visits, patientHasScreeningPeriodEnabled) => {
    const options = []
    // eslint-disable-next-line
    visits.map((visit, i) => {
      if (isVisitUnlocked(visit) && !isVisitUnscheduled(visit) && (patientHasScreeningPeriodEnabled ? !isScreeningVisit(visit) : true) && !isFirstVisit(visits, visit)) {
        options.push(this._createVisitOption(visit, i))
      }
    })
    return options
  }

  // eslint-disable-next-line no-unused-vars
  _createVisitOption = (visit, indexOfVisit) => ({
    id: visit.id,
    value: indexOfVisit,
    label: visit.visit.title,
    status: visit.status,
  })
}

VisitSelector.propTypes = {
  visit: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  patient: PropTypes.object.isRequired,
  visits: PropTypes.array,
  dummyVisit: PropTypes.string,
  serverTime: PropTypes.string,
}

VisitSelector.defaultProps = {
  visit: null,
  visits: [],
  dummyVisit: null,
  serverTime: null,
}

const mapStateToProps = state => ({
  patient: state.patients.patient,
  visits: state.visits.visitList,
  serverTime: state.timeTravel.serverTime,
})

export default connect(mapStateToProps, null)(VisitSelector)
