import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import shortid from 'shortid'
import Switch from "../inputs/SwitchPdi"
import PatientPDIHeaderTableRow from './PatientPDIHeaderTableRow'
import PatientPDIContentTableRow from './PatientPDIContentTableRow'
import { getStatusOfPDI } from '../../utils/DataHelper'
import visibleForRole from '../../hocs/visibleForRole'


class PatientPDIStatusTable extends Component {
  render() {
    const { pdiList, match } = this.props
    // eslint-disable-next-line camelcase
    const { study_id, patient_id } = match.params
    return (
      <div className="o-table pdi-status-table">
        <table>
          <tbody>
            <PatientPDIHeaderTableRow tableHeaders={ ["Type", "Description", "Status"] } />
            {
              pdiList.map(pdi => (
                <PatientPDIContentTableRow key={ shortid.generate() } tableContent={ [pdi.type, pdi.description, this._renderStatusAndToggle(pdi.active, pdi.id, pdi.description, pdi.type, pdi.canBeDeactivated, study_id, patient_id)] } />
              ))
                }
          </tbody>
        </table>
      </div>
    )
  }

  _renderStatusAndToggle = (status, reference, description, type, canBeDeactivated, studyId, patientId) => {
    const PdiSwitch = visibleForRole(["investigator", "site_user"],
      <Switch
        className="react-switch-pdi-status"
        checked={ status }
        description={ description }
        reference={ reference }
        type={ type }
        studyId={ studyId }
        patientId={ patientId }
      />)
    return (
      <div className="pdi-status">
        { getStatusOfPDI(status) }
        { !!canBeDeactivated && <PdiSwitch /> }
      </div>
    )
  }
}

export const mapStateToProps = state => ({ pdiList: state.pdis.pdiList })

PatientPDIStatusTable.propTypes = {
  pdiList: PropTypes.array,
  match: PropTypes.object.isRequired,
}

PatientPDIStatusTable.defaultProps = { pdiList: [] }

export default withRouter(connect(mapStateToProps, null)(PatientPDIStatusTable))
