import React from 'react'
import PropTypes from 'prop-types'
import { Route, Link } from 'react-router-dom'
import { isNil } from 'ramda'
import cc from 'classcat'

import CollapsePickerItem from '../collapsePicker/CollapsePickerItem'

const SiteLink = (props) => {
  const { siteId, handleClick, siteClass, parentPath } = props
  if (!isNil(siteId)) {
    return (
      <Route
        path={ `${parentPath}/site/${siteId}` }
        children={ ({ match }) => ( // eslint-disable-line react/no-children-prop
          <CollapsePickerItem
            onItemClick={ handleClick }
            itemClass={ cc([{ selected: match }, siteClass]) }>
            <Link
              to={ `${parentPath}/site/${siteId}` }
              tabIndex={ -1 }>
              { `Site ${siteId}` }
            </Link>
          </CollapsePickerItem> // eslint-disable-line comma-dangle
        ) } />
    )
  }
  return null
}

SiteLink.propTypes = {
  parentPath: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  siteClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
}

SiteLink.defaultProps = { siteClass: [] }

export default SiteLink
