import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { isNil, isEmpty } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'

import { isToday, isAfterToday } from '../../utils/MomentHelper'
import getConfigOrEnvVariable, { getTelevisitsApplicationUri } from '../../utils/ConfigHelper'
import ApplicationConfig from "../../config/ApplicationConfig"
import { TELEVISITS_COMPATIBILITY_ERROR_MESSAGE } from '../../config/constants'
import { isStudyArchived } from '../../utils/StudyHelper'
import { isEdge, isIE } from '../../utils/BrowserHelper'
import MainButtonGroup from '../../components/buttons/MainButtonGroup'
import MainButton from '../../components/buttons/MainButton'
import visibleForRole from '../../hocs/visibleForRole'
import LoadingModal from '../../components/modals/LoadingModal'
import BaseModal from '../../components/modals/BaseModal'
import PdiActions from '../../redux/PdiRedux'
import ContextActions from '../../redux/ContextRedux'

import HintLabel from '../../components/HintLabel'
import VisitOverview from '../../components/tables/VisitOverview'
import ErrorHandler from '../../components/error/ErrorHandler'

import TabContainer from '../layout/TabContainer'

export class SiteOverview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      televisitsApplicationUri: '',
      showTelevisitsCompatibilityError: false,
      showExportAllPdrsModal: false,
    }
  }

  componentDidMount() {
    const { fetchContext } = this.props
    Promise.resolve(ApplicationConfig).then((appConfig) => {
      const televisitsApplicationUri = getTelevisitsApplicationUri(appConfig)
      const isStudyAgnosticEnabled = getConfigOrEnvVariable(appConfig.enableStudyAgnostic, process.env.REACT_APP_STUDY_AGNOSTIC)
      const isStudyAgnostic = isStudyAgnosticEnabled.toLowerCase() === "true"
      this.setState({ televisitsApplicationUri })
      fetchContext(isStudyAgnostic, true)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { busyExportingAllPdrs, exportAllPdrsError } = this.props
    const allPdrsHaveBeenExported = prevProps.busyExportingAllPdrs && !busyExportingAllPdrs && !exportAllPdrsError
    if (allPdrsHaveBeenExported) {
      this._closeExportAllPdrsModal()
    }
  }

  render() {
    const { televisitsApplicationUri, showTelevisitsCompatibilityError, showExportAllPdrsModal } = this.state
    const { match, site, study, fetchPatientsError, serverTime, exportAllPdrs, exportAllPdrsError, busyExportingAllPdrs } = this.props
    // eslint-disable-next-line camelcase
    const { site_id } = match.params

    const hasError = !isNil(fetchPatientsError) || showTelevisitsCompatibilityError
    const hasPatients = !isNilOrEmpty(site) && !isNilOrEmpty(site.patients)
    const isArchivedStudy = !isNilOrEmpty(study) && isStudyArchived(study.workflow)
    return (
      <TabContainer>
        <Helmet>
          <title>
            {
              // eslint-disable-next-line camelcase
              `Site ${site_id}`
            }
          </title>
        </Helmet>
        { hasError && this._renderErrors([fetchPatientsError, { message: TELEVISITS_COMPATIBILITY_ERROR_MESSAGE }]) }
        { !isNilOrEmpty(study) && hasPatients && this._renderBulkExportPDR(study.id, site.id, exportAllPdrs)}
        { hasPatients && this._renderSiteOverview(this._parseData(site.patients, serverTime), this._openVirtualVisit(televisitsApplicationUri, study.id), isArchivedStudy) }
        { showExportAllPdrsModal && this._renderExportAllPdrsModal(study.id, site.id, exportAllPdrs, exportAllPdrsError, busyExportingAllPdrs) }
        { !isNilOrEmpty(study) && !hasPatients && this._renderNoOverviewAvailable() }
      </TabContainer>
    )
  }

    // export all pdrs
    _renderBulkExportPDR = (studyId, siteId, exportAllPdrs) => {
      const ExportButton = visibleForRole(['investigator', 'site_user', 'application_support', 'cro', 'data_review', 'study_configurator', 'inspector', 'sponsor'],
        <MainButtonGroup buttonGroupClass="site-overview-buttons u-margin--zero">
          <MainButton
            buttonClass="blue"
            handleClick={ () => this._downloadAllPdrs(studyId, siteId, exportAllPdrs) }
            label="Export all PDRs"
            icon={ { name: "download", size: 25 } }
            id="button-export-all-pdrs" />
        </MainButtonGroup>)
      return (
        <ExportButton />
      )
    }

    _renderExportAllPdrsModal = (studyId, siteId, exportAllPdrs, exportAllPdrsError, busyExportingAllPdrs) => (
      <BaseModal
        title="Export all PDRs"
        handleClose={ this._closeExportAllPdrsModal }
        forceInteraction={ busyExportingAllPdrs }>
        <LoadingModal
          loading={ busyExportingAllPdrs }
          loadingHint={ `Exporting all PDRs for ${siteId}, this might take a few minutes` }
          error={ exportAllPdrsError }
          closeLoadingModal={ this._closeExportAllPdrsModal }
          retryAction={ () => this._downloadAllPdrs(studyId, siteId, exportAllPdrs) } />
      </BaseModal>
    )

    _downloadAllPdrs = (studyId, siteId, exportAllPdrs) => {
      this._openExportAllPdrsModal()
      exportAllPdrs(studyId, siteId)
    }

    _openExportAllPdrsModal = () => {
      this.setState({ showExportAllPdrsModal: true })
    }

    _closeExportAllPdrsModal = () => {
      this.setState({ showExportAllPdrsModal: false })
    }

  _openVirtualVisit = (televisitsApplicationUri, studyName) => (virtualVisitId) => {
    if (isEdge() || isIE()) {
      this.setState({ showTelevisitsCompatibilityError: true })
    } else {
      const url = `${televisitsApplicationUri}/televisit?studyId=${studyName}&visitId=${virtualVisitId}`
      return window.open(url, "_blank") || window.location.replace(url)
    }
    return null
  }

  _parseData = (patients, serverTime) => {
    const todayVisits = []
    const upcomingVisits = []
    const completedVisits = []
    // TODO: filter patients in screening period, they'll have an upcomingVisit equal to today but that's wrong, because they've just been added and it's the screening visit
    // eslint-disable-next-line
    patients.map((patient) => {
      const { upcomingVisitDate, upcomingVisitDateNext, upcomingVisitNext, upcomingVisitNextIsVirtual } = patient
      if (!isNil(upcomingVisitDate) && isToday(upcomingVisitDate, serverTime)) {
        todayVisits.push(patient)
      } else if (!isNil(upcomingVisitDate) && isAfterToday(upcomingVisitDate, serverTime)) {
        upcomingVisits.push(patient)
      } else if (!isNil(upcomingVisitDateNext) && isAfterToday(upcomingVisitDateNext, serverTime)) {
        const obj = Object.assign({}, patient, {
          upcomingVisit: upcomingVisitNext,
          upcomingVisitDate: upcomingVisitDateNext,
          upcomingVisitNext: null,
          upcomingVisitDateNext: null,
          upcomingVisitIsVirtual: upcomingVisitNextIsVirtual,
          upcomingVisitNextIsVirtual: null,
        }) // swap upcomingVisit objects so the next visit is under "upcoming visit"
        upcomingVisits.push(obj)
      }
      if (isEmpty(patient.upcomingVisit) && isEmpty(patient.upcomingVisitNext)) {
        completedVisits.push(patient)
      }
    })
    return [todayVisits, upcomingVisits, completedVisits]
  }

  _renderSiteOverview = (visits, openVirtualVisit, isArchivedStudy, studyName) => (
    <div>
      <VisitOverview
        label="Today's scheduled patient visits"
        studyName={ studyName }
        openVirtualVisit={ openVirtualVisit }
        isArchivedStudy={ isArchivedStudy }
        patientsVisits={ visits[0] } />
      <VisitOverview
        label="Upcoming patient visits"
        studyName={ studyName }
        isArchivedStudy={ isArchivedStudy }
        showVirtualVisitLabel={ true }
        patientsVisits={ visits[1] } />
      <VisitOverview
        isCompletedTable={ true }
        label="Patients that completed the study"
        patientsVisits={ visits[2] } />
    </div>
  )

  _renderNoOverviewAvailable = () => (
    <HintLabel>
      No site overview available
    </HintLabel>
  )

  _renderErrors = errors => <ErrorHandler error={ errors } />
}

SiteOverview.propTypes = {
  match: PropTypes.object.isRequired,
  site: PropTypes.object,
  study: PropTypes.object,
  fetchPatientsError: PropTypes.object,
  serverTime: PropTypes.string,
  exportAllPdrsError: PropTypes.object,
  exportAllPdrs: PropTypes.func.isRequired,
  busyExportingAllPdrs: PropTypes.bool.isRequired,
  fetchContext: PropTypes.func.isRequired,
}

SiteOverview.defaultProps = {
  site: null,
  study: null,
  fetchPatientsError: null,
  serverTime: null,
  exportAllPdrsError: null,
}

const mapStateToProps = state => ({
  site: state.set.site,
  study: state.set.study,
  serverTime: state.timeTravel.serverTime,
  fetchPatientsError: state.patients.fetchPatientsError,
  busyExportingAllPdrs: state.pdis.busyExportingAllPdrs,
  exportAllPdrsError: state.pdis.exportAllPdrsError,
})

export const mapDispatchToProps = dispatch => ({ exportAllPdrs: (studyId, siteId) => dispatch(PdiActions.exportAllPdrs(studyId, siteId)),
  fetchContext: (isStudyAgnostic, navigateHome) => dispatch(ContextActions.fetchContext(isStudyAgnostic, navigateHome)) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteOverview))
