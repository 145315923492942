import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import ErrorHandler from '../error/ErrorHandler'
import { TELEVISITS_COMPATIBILITY_ERROR_MESSAGE, ICON_VIDEO } from '../../config/constants'
import { frontendTimeFormat } from '../../utils/MomentHelper'
import Icon from '../Icon'

function TelevisitsNotificationContent(props) {
  const { virtualVisitId, visitName, visitPlannedOnDate, studyName, handleClick, showError } = props
  const [showTelevisitsCompatibilityError] = useState(showError)

  const _renderError = error => (
    <ErrorHandler
      error={ error }
    />
  )

  return (
    <div>
      <div className="notification-error">
        { showTelevisitsCompatibilityError && _renderError({ message: TELEVISITS_COMPATIBILITY_ERROR_MESSAGE }) }
      </div>
      <div className="notification-visit-details">
        <div className="notification-visit-name-time">
          <div className="notification-visit-name">
            <strong>Visit</strong>
            <p>{ visitName }</p>
          </div>
          <div className="notification-visit-time">
            <strong>Scheduled</strong>
            <p>{ moment.parseZone(visitPlannedOnDate).format(frontendTimeFormat) }</p>
          </div>
        </div>
        <div className="notification-study-name">
          <strong>Study</strong>
          <p>{ studyName }</p>
        </div>
      </div>
      <div
        className="virtual-visit-button"
        onClick={ () => handleClick(virtualVisitId) }
        role="button"
        tabIndex={ 0 }
        onKeyPress={ null }
        id="button-virtual-visit">
        <Icon
          name={ ICON_VIDEO }
          size={ 20 }
        />
        <div className="virtual-visit-button-text">
          Join Virtual Visit
        </div>
      </div>
    </div>

  )
}

TelevisitsNotificationContent.propTypes = {
  virtualVisitId: PropTypes.string,
  visitName: PropTypes.string,
  visitPlannedOnDate: PropTypes.object,
  studyName: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  showError: PropTypes.bool,
}

TelevisitsNotificationContent.defaultProps = {
  virtualVisitId: '',
  visitName: '',
  visitPlannedOnDate: null,
  studyName: '',
  showError: false,
}

export default TelevisitsNotificationContent
