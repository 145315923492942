import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { is, isEmpty } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'

import TabContainer from '../layout/TabContainer'
import HintLabel from '../../components/HintLabel'
import ErrorHandler from '../../components/error/ErrorHandler'

class StartContainer extends Component {
  render() {
    const { studies, fetchingContext, fetchContextError } = this.props
    const hasStudies = is(Array, studies) && !isEmpty(studies)
    const hasError = !isNilOrEmpty(fetchContextError)

    return (
      <TabContainer>
        { hasError && this._renderError(fetchContextError) }
        { hasStudies && this._renderSelectStudyText() }
        { !fetchingContext && !hasStudies && !hasError && this._renderNoStudiesAvailableScreen() }
      </TabContainer>
    )
  }


  _renderError = error => <ErrorHandler error={ error } />

  _renderNoStudiesAvailableScreen = () => (
    <HintLabel>
      No studies available
    </HintLabel>
  )

  _renderSelectStudyText = () => (
    <HintLabel>
      Please select your study from the list of available studies.
    </HintLabel>
  )
}

StartContainer.propTypes = {
  studies: PropTypes.array,
  fetchingContext: PropTypes.bool.isRequired,
  fetchContextError: PropTypes.object,
}

StartContainer.defaultProps = {
  studies: [],
  fetchContextError: null,
}

const mapStateToProps = state => ({
  studies: state.set.studies,
  fetchingContext: state.context.fetchingContext,
  fetchContextError: state.context.fetchContextError,
})


export default withRouter(connect(mapStateToProps, null)(StartContainer))
