import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { equals, is, isEmpty, isNil } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'
import { withRouter } from 'react-router-dom'
import shortid from 'shortid'

import QuestionnairesActions from '../../redux/QuestionnairesRedux'

import reloadRoute from '../../utils/RouteHelper'
import { groupDataByTitle } from '../../utils/DataHelper'
import { fetchVisitData } from '../../utils/VisitHelper'
import { getBaselineDummyVisitText } from '../../utils/StudyHelper'

import HintLabel from '../../components/HintLabel'
import ErrorHandler from '../../components/error/ErrorHandler'
import VisitSelector from '../../components/inputs/VisitSelector'

import PatientTable from '../../components/tables/PatientTable'
import PatientQuestionnaireTableRowItem from '../../components/tables/PatientQuestionnaireTableRowItem'

class PatientTabQuestionnaires extends Component {
  constructor(props) {
    super(props)

    this.state = { visitId: null }
  }

  componentWillReceiveProps(nextProps) {
    // ROUTE HAS BEEN CLICKED AGAIN without switching to other routes
    const { visitId } = this.state
    const { match, location, fetchQuestionnaires } = this.props
    const { study_id, patient_id } = match.params // eslint-disable-line camelcase
    const thisKey = location.key
    const nextKey = nextProps.location.key
    if (!isNilOrEmpty(visitId)) {
      reloadRoute(thisKey, nextKey, () => fetchVisitData(study_id, patient_id, visitId, fetchQuestionnaires))
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { visitId } = this.state
    const previousVisitId = visitId
    const nextVisitId = nextState.visitId
    if (!equals(nextVisitId, previousVisitId)) {
      const { match, fetchQuestionnaires } = this.props
      const { study_id, patient_id } = match.params // eslint-disable-line camelcase
      fetchVisitData(study_id, patient_id, nextVisitId, fetchQuestionnaires)
    }
  }

  render() {
    const { questionnaires, dummyVisit, fetchQuestionnairesError, busyFetchingQuestionnaires, visits, fetchVisitsError } = this.props

    const { visitId } = this.state

    const hasError = !isNil(fetchQuestionnairesError) || !isNil(fetchVisitsError)

    const hasQuestionnaires = is(Array, questionnaires) && !isEmpty(questionnaires)
    const hasVisits = is(Array, visits) && !isEmpty(visits)
    const hasVisitSelected = !isNilOrEmpty(visitId)

    return (
      <div className="c-patient">
        { !busyFetchingQuestionnaires && hasError && this._renderError([fetchQuestionnairesError, fetchVisitsError]) }
        { hasVisits && <VisitSelector dummyVisit={ getBaselineDummyVisitText(dummyVisit) } onChange={ this._visitChanged } /> }
        { hasVisits && !busyFetchingQuestionnaires && !hasError && hasQuestionnaires && hasVisitSelected && this._renderData(groupDataByTitle(questionnaires)) }
        { !busyFetchingQuestionnaires && !hasError && !hasQuestionnaires && this._renderNoDataAvailable() }
      </div>
    )
  }

  _renderError = error => <ErrorHandler error={ error } />

  _renderNoDataAvailable = () => (
    <HintLabel
      hintClass="u-margin--vertical"
      size={ 20 }>
      No PRO data available
    </HintLabel>
  )

  _renderData = questionnaires => (
    <PatientTable headers={ [{
      label: 'PRO Name',
      colSpan: 4,
      id: shortid.generate(),
    }] }>
      { this._renderTableContent(questionnaires) }
    </PatientTable>
  )

  _visitChanged = (option) => {
    this.setState({ visitId: parseInt(option.id, 0) })
  }

  _renderTableContent = data => data && Object.keys(data).map(questionnaire => (
    <tbody key={ data[questionnaire][0].questionnaireId }>
      <tr>
        <td
          colSpan={ 4 }
          className="o-table__item--heading">
          { questionnaire }
        </td>
      </tr>
      { data[questionnaire] && data[questionnaire].map(questionnaireData => (
        <PatientQuestionnaireTableRowItem
          data={ questionnaireData }
          key={ shortid.generate() } />
      )) }
    </tbody>
  ))
}

PatientTabQuestionnaires.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  // questionnaires
  questionnaires: PropTypes.array,
  dummyVisit: PropTypes.string,
  fetchQuestionnaires: PropTypes.func.isRequired,
  fetchQuestionnairesError: PropTypes.object,
  busyFetchingQuestionnaires: PropTypes.bool.isRequired,
  // visits
  visits: PropTypes.array,
  fetchVisitsError: PropTypes.object,
}

PatientTabQuestionnaires.defaultProps = {
  // questionnaires
  questionnaires: [],
  dummyVisit: null,
  fetchQuestionnairesError: null,
  // visits
  visits: [],
  fetchVisitsError: null,
}

const mapStateToProps = state => ({
  // questionnaires
  questionnaires: state.questionnaires.questionnaireList,
  dummyVisit: state.questionnaires.dummyVisit,
  fetchQuestionnairesError: state.questionnaires.fetchQuestionnairesError,
  busyFetchingQuestionnaires: state.questionnaires.busyFetchingQuestionnaires,
  // visits
  visits: state.visits.visitList,
  fetchVisitsError: state.visits.fetchVisitsError,
})

const mapDispatchToProps = dispatch => ({ fetchQuestionnaires: (studyId, patientId, visitId) => dispatch(QuestionnairesActions.fetchQuestionnaires(studyId, patientId, visitId)) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientTabQuestionnaires))
