import { groupBy } from 'ramda'

import { NOT_ACKNOWLEDGED, DONE, NOT_DONE } from '../config/constants'

export function getStatusOfPDI(status) {
  return status ? "Active" : "Inactive"
}

export function groupDataByTitle(data) {
  if (data) {
    const groupEntries = groupBy(entries => entries.title)
    return groupEntries(data)
  }
  return false
}

export function getStatusOfActivityDataPoint(status) {
  if (typeof status === 'undefined') {
    return NOT_ACKNOWLEDGED
  }
  if (status) {
    return DONE
  }
  return NOT_DONE
}

export function extractTogglePdisFromResponse(data) {
  return (data && data.pdiStatuses) || data
}

export function extractDataFromResponse(data) {
  return (data && data._embedded && data._embedded.item) || data
}

export function extractDailyTasksFromResponse(data) {
  return (data && data._embedded && data._embedded.dailyTasks) || data
}

export function extractQuestionnairesFromResponse(data) {
  return (data && data._embedded && data._embedded.questionnaires) || data
}

export function extractEventsFromResponse(data) {
  return (data && data._embedded && data._embedded.events) || data
}

export function extractStudiesFromContextResponse(data) {
  return data && data._embedded && data._embedded.studies
}

export function extractTemplatesFromResponse(data) {
  return (data && data.templates) || data
}

export function extractFormsFromResponse(data) {
  return (data && data.forms) || data
}
