import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DateTime from 'react-datetime'
import { is } from 'ramda'
import cc from 'classcat'

import { ICON_CALENDAR, ICON_EDIT_FA } from '../../config/constants'

import { isSameOrAfterToday, isBeforeToday, backendFormat } from '../../utils/MomentHelper'

import Icon from '../Icon'

class TableDateTimePicker extends Component {
  // we need to use a value from the state because if we let the datePicker library maintain it
  // it loses the locale and messes up the time. So the state, defaultDateTime, moment(dateTimeFromState) is very specific code to
  // force the locale on the datetime-picker.
  constructor(props) {
    super(props)
    const { initialDateTime } = this.props
    const defaultDateTime = moment.parseZone(initialDateTime)
    this.state = { dateTimeFromState: defaultDateTime }
  }

  componentDidUpdate(prevProps, prevState) {
    const { initialDateTime, visitDates, busyChangingDatePickerDate } = this.props
    const { dateTimeFromState } = this.state

    // check if visit dates contains the updated datetime and if not, reset the date back to the initialDateTime
    const noVisitHasChanged = visitDates.every(visitDate => visitDate === dateTimeFromState.format(backendFormat))
    if (!noVisitHasChanged && dateTimeFromState === prevState.dateTimeFromState && !busyChangingDatePickerDate) {
      //* allowed to use setState under very specific conditions
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ dateTimeFromState: moment.parseZone(initialDateTime) })
    }
  }

  render() {
    const { initialDateTime, onChangeDate, disabled, tooltip, serverTime } = this.props
    const { allowDatesInPast, allowBeginDateLimitAsWell, beginDateLimit, endDateLimit, timeFormat, visitDates, visitIsUnscheduled } = this.props
    const { dateTimeFromState } = this.state
    const isDateBeforeToday = isBeforeToday(initialDateTime, serverTime)

    return (
      <div
        className={ cc(["table-date-picker-container", { "table-date-picker-container-past": isDateBeforeToday }, { disabled }]) }
        id="date-picker"
        title={ disabled ? tooltip : null }>
        <DateTime
          inputProps={ { readOnly: true } }
          value={ dateTimeFromState }
          dateFormat="DD-MMM-YYYY"
          className={ cc(["table-date-picker", { "table-date-picker-past": isDateBeforeToday }, { disabled }]) }
          timeFormat={ timeFormat }
          onClose={ this._blur(onChangeDate, initialDateTime) }
          onChange={ this._dateChanged }
          isValidDate={ this._isValidDate(beginDateLimit, endDateLimit, allowDatesInPast, allowBeginDateLimitAsWell, visitDates, visitIsUnscheduled) } />
        { isDateBeforeToday ? this._renderIcon(ICON_EDIT_FA) : this._renderIcon(ICON_CALENDAR)}
      </div>
    )
  }

  _renderIcon = iconName => (
    <Icon
      name={ iconName }
      size={ 20 } />
  )

  _dateChanged = (newDate) => {
    this.setState(() => ({ dateTimeFromState: newDate }))
  }

  _blur = (onChangeDate, initialDateTime) => (newDate) => {
    const hasDateChanged = !moment.parseZone(initialDateTime).isSame(newDate, 'minute')
    return hasDateChanged && newDate && onChangeDate(newDate.format(backendFormat), initialDateTime)
  }

  _isValidDate = (beginDateLimit, endDateLimit, allowDatesInPast, allowBeginDateLimitAsWell, visitDates, visitIsUnscheduled, serverTime) => (proposedDate) => {
    let respectsBeginDateLimit = true
    let respectsEndDateLimit = true
    let isSameOrAfterTodayOrAllowedInPast = true
    let respectsVisitDates = true

    const respectsVisitDatesArray = []

    if (visitIsUnscheduled) {
      visitDates.forEach(visitDate => respectsVisitDatesArray.push((proposedDate.isBefore(moment.parseZone(visitDate), 'day') || proposedDate.isAfter(moment.parseZone(visitDate), 'day'))))
      respectsVisitDatesArray.forEach((visitDate) => {
        respectsVisitDates = respectsVisitDates && visitDate
        return respectsVisitDates
      })
    }

    isSameOrAfterTodayOrAllowedInPast = allowDatesInPast || isSameOrAfterToday(proposedDate, serverTime)

    if (is(String, beginDateLimit)) {
      const beginDateLimitZoneParsed = moment.parseZone(beginDateLimit)
      respectsBeginDateLimit = allowBeginDateLimitAsWell ? proposedDate.isSameOrAfter(beginDateLimitZoneParsed, 'day') : proposedDate.isAfter(beginDateLimitZoneParsed, 'day')
    }
    if (is(String, endDateLimit)) {
      const endDateLimitZoneParsed = moment.parseZone(endDateLimit)
      respectsEndDateLimit = proposedDate.isBefore(endDateLimitZoneParsed, 'day')
    }

    return isSameOrAfterTodayOrAllowedInPast && respectsBeginDateLimit && respectsEndDateLimit && respectsVisitDates
  }
}

TableDateTimePicker.propTypes = {
  beginDateLimit: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  endDateLimit: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  initialDateTime: PropTypes.string,
  onChangeDate: PropTypes.func.isRequired,
  timeFormat: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  allowDatesInPast: PropTypes.bool,
  allowBeginDateLimitAsWell: PropTypes.bool,
  visitDates: PropTypes.array,
  visitIsUnscheduled: PropTypes.bool,
  busyChangingDatePickerDate: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  serverTime: PropTypes.string,
}

TableDateTimePicker.defaultProps = {
  timeFormat: "HH:mm",
  allowDatesInPast: false,
  allowBeginDateLimitAsWell: false,
  beginDateLimit: null,
  endDateLimit: null,
  initialDateTime: null,
  visitDates: [],
  visitIsUnscheduled: false,
  busyChangingDatePickerDate: false,
  disabled: false,
  tooltip: null,
  serverTime: null,
}

export default TableDateTimePicker
