import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import ProfileBadge from './ProfileBadge'

import SidebarToggle from '../buttons/SidebarToggle'

export const StudyIdTitle = ({ match }) => ` - ${match.params.study_id}`
export const SiteIdTitle = ({ match }) => ` (Site ${match.params.site_id})`
export const PatientIdTitle = ({ match }) => ` (Patient ${match.params.patient_id})`

const NavbarHeader = (props) => {
  const { handleSidebarClick } = props
  return (
    <div className="navbar-header">
      <SidebarToggle handleClick={ handleSidebarClick } />
      <h1 className="navbar-header__title">
        <span>
          myUCB Insights
        </span>
        <Route
          path="/study/:study_id"
          render={ StudyIdTitle } />
        <Route
          path="/study/:study_id/site/:site_id"
          render={ SiteIdTitle } />
        <Route
          path="/study/:study_id/site/:site_id/patient/:patient_id"
          render={ PatientIdTitle } />
      </h1>
      <ProfileBadge />
    </div>
  )
}

NavbarHeader.propTypes = { handleSidebarClick: PropTypes.func.isRequired }

export default NavbarHeader
