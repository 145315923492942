import React, { useState, useEffect } from 'react'
import cc from 'classcat'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import { ICON_H_SQUARE, ICON_VIDEO } from "../../config/constants"
import BaseModal from '../modals/BaseModal'
import ConfirmationModal from '../modals/ConfirmationModal'
import OnSiteLabel from '../labels/OnSiteLabel'
import VirtualLabel from '../labels/VirtualLabel'

function ToggleVirtualVisit(props) {
  const { visitIsVirtual, id, confirmToggleVirtualVisit, resetToggleVirtualVisitError, showLabel, error, loading, isStudyArchived } = props
  const [active, setActive] = useState(visitIsVirtual)
  const [showConfirmationModal, setShowConfirmationModal] = useState()

  const _openModal = () => {
    setActive(!active)
    setShowConfirmationModal(true)
  }

  const _closeModal = () => {
    setActive(!active)
    setShowConfirmationModal(false)
    resetToggleVirtualVisitError()
  }

  useEffect(() => {
    setShowConfirmationModal(false)
  }, [visitIsVirtual])

  const _handleConfirmed = () => {
    try {
      confirmToggleVirtualVisit(active)
    } catch (e) {
      console.log(e)
    }
  }

  const _renderConfirmationModal = () => (
    <BaseModal
      title="CONFIGURE VISIT"
      handleClose={ _closeModal }
      forceInteraction={ loading }
    >
      <ConfirmationModal
        title="Are you sure?"
        details={ `This visit will appear as ${active ? 'a virtual' : 'an on site'} visit on the patient's device` }
        handleConfirmed={ _handleConfirmed }
        handleCanceled={ _closeModal }
        error={ error }
        loading={ loading }
      />
    </BaseModal>
  )

  const _renderOnSiteLabel = () => (
    <OnSiteLabel />
  )

  const _renderVirtualLabel = () => (
    <VirtualLabel />
  )

  const _renderButtons = () => (
    <div className={ cc(["toggle-virtual-visit-buttons", { "toggle-virtual-visit-buttons--archived": isStudyArchived }]) }>
      <div
        className={ cc(["toggle-on-site-button", { "toggle-on-site-button--active": !active }]) }
        onClick={ _openModal }
        onKeyPress={ null }
        id={ id }
        role="button"
        tabIndex={ 0 }
        >
        <Icon
          name={ ICON_H_SQUARE }
          size={ 20 }
            />
        <div className={ cc(["toggle-on-site-button__text", { "toggle-on-site-button__text--active": !active }]) }>
          On Site
        </div>
      </div>
      <div
        className={ cc(["toggle-virtual-button", { "toggle-virtual-button--active": active }]) }
        onClick={ _openModal }
        onKeyPress={ null }
        id={ id }
        role="button"
        tabIndex={ 0 }
        >
        <Icon
          name={ ICON_VIDEO }
          size={ 20 }
            />
        <div className={ cc(["toggle-virtual-button__text", { "toggle-virtual-button__text--active": active }]) }>
          Virtual
        </div>
      </div>
    </div>
  )

  return (
    <div className="toggle-virtual-visit">
      { showConfirmationModal && _renderConfirmationModal() }
      { showLabel && !visitIsVirtual && _renderOnSiteLabel() }
      { showLabel && visitIsVirtual && _renderVirtualLabel() }
      { !showLabel && _renderButtons()}
    </div>
  )
}

ToggleVirtualVisit.propTypes = {
  id: PropTypes.string.isRequired,
  visitIsVirtual: PropTypes.bool,
  confirmToggleVirtualVisit: PropTypes.func.isRequired,
  resetToggleVirtualVisitError: PropTypes.func.isRequired,
  isStudyArchived: PropTypes.bool.isRequired,
  showLabel: PropTypes.bool,
  error: PropTypes.object,
  loading: PropTypes.bool,
}

ToggleVirtualVisit.defaultProps = {
  visitIsVirtual: false,
  showLabel: false,
  error: null,
  loading: false,
}


export default ToggleVirtualVisit
