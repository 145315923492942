import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { is, isEmpty } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'
import shortid from 'shortid'

import HintLabel from '../HintLabel'

class ErrorBox extends Component {
  render() {
    const { error } = this.props
    const { title, message, hint, details } = error

    return (
      <HintLabel
        size={ 16 }
        hintClass="u-margin--vertical error">
        { title && (
          <p className="o-hint-text--title">
            { title }
          </p>
        ) }
        { message && (
          <p>
            { message }
          </p>
        ) }
        { hint && (
          <p>
            { hint }
          </p>
        ) }
        { details && this._renderDetails(details) }
      </HintLabel>
    )
  }

  _renderText = text => (
    <p>
      { text }
    </p>
  )

  _renderDetails = (details) => {
    if (is(Array, details) && !isEmpty(details)) {
      return (
        <ul className="o-hint-text--details">
          { details.map(detail => !isNilOrEmpty(detail.message) && (
            <li key={ shortid.generate() }>
              { detail.message }
            </li>
          )) }
        </ul>
      )
    }
    return (
      <p className="o-hint-text--details">
        { details }
      </p>
    )
  }
}

ErrorBox.propTypes = {
  error: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
    hint: PropTypes.string,
    details: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]),
  }).isRequired,
}

export default ErrorBox
