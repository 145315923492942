import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  MdFileDownload, MdFileUpload, MdAddCircleOutline, MdCheck, MdSettings, MdLanguage, MdDelete, MdEdit, MdHome, MdList, MdExpandMore,
  MdChevronRight, MdFlashOn, MdCheckCircle, MdReportProblem, MdInfoOutline, MdSkipNext, MdSkipPrevious, MdPlaylistAdd, MdHighlightOff,
  MdChevronLeft, MdDone,
} from 'react-icons/md'
import { FaSignOutAlt, FaRegCalendar, FaCalendarAlt, FaStepForward, FaStepBackward, FaBan, FaRegEdit, FaHSquare, FaVideo } from 'react-icons/fa'

import {
  ICON_LOCATION_RIGHT, ICON_DOWNLOAD, ICON_UPLOAD, ICON_LOGOUT, ICON_PLUS, ICON_SETTINGS, ICON_LANGUAGE, ICON_DELETE, ICON_EDIT_MD,
  ICON_EDIT_FA, ICON_HOME, ICON_LIST, ICON_CALENDAR, ICON_CALENDAR_ALT, ICON_CIRCLE_CROSS, ICON_ARROW_DOWN, ICON_ARROW_RIGHT, ICON_FLASH, ICON_STEP_FORWARD,
  ICON_STEP_BACKWARD, ICON_CHECK_CIRCLE, ICON_DANGER, ICON_INFO, ICON_START, ICON_STOP, ICON_ADD_LIST, ICON_BAN, ICON_ALLOW, ICON_ARROW_LEFT,
  ICON_CHECK_MARK, ICON_H_SQUARE, ICON_VIDEO,
} from '../config/constants'

class Icon extends Component {
  render() {
    const { name, size, color, rotate, style, location } = this.props

    let renderedIcon = null
    let updatedStyle = style
    if (location === ICON_LOCATION_RIGHT) {
      updatedStyle = Object.assign({}, style, { marginRight: "-0.625em", marginLeft: "0.625em" })
    }
    const iconStyle = Object.assign({}, { transform: `rotate(${rotate}deg)` }, updatedStyle)

    switch (name) {
      case ICON_DOWNLOAD:
        renderedIcon = (
          <MdFileDownload
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_UPLOAD:
        renderedIcon = (
          <MdFileUpload
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_LOGOUT:
        renderedIcon = (
          <FaSignOutAlt
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_PLUS:
        renderedIcon = (
          <MdAddCircleOutline
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_SETTINGS:
        renderedIcon = (
          <MdSettings
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_LANGUAGE:
        renderedIcon = (
          <MdLanguage
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_DELETE:
        renderedIcon = (
          <MdDelete
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_EDIT_MD:
        renderedIcon = (
          <MdEdit
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_EDIT_FA:
        renderedIcon = (
          <FaRegEdit
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_HOME:
        renderedIcon = (
          <MdHome
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_LIST:
        renderedIcon = (
          <MdList
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_CALENDAR:
        renderedIcon = (
          <FaRegCalendar
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_CALENDAR_ALT:
        renderedIcon = (
          <FaCalendarAlt
            size={ size }
            color={ color }
            style={ iconStyle }
            />
        )
        break
      case ICON_CIRCLE_CROSS:
        renderedIcon = (
          <MdHighlightOff
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_ARROW_DOWN:
        renderedIcon = (
          <MdExpandMore
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_ARROW_RIGHT:
        renderedIcon = (
          <MdChevronRight
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_FLASH:
        renderedIcon = (
          <MdFlashOn
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_STEP_FORWARD:
        renderedIcon = (
          <FaStepForward
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_STEP_BACKWARD:
        renderedIcon = (
          <FaStepBackward
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_CHECK_CIRCLE:
        renderedIcon = (
          <MdCheckCircle
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_CHECK_MARK:
        renderedIcon = (
          <MdDone
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_DANGER:
        renderedIcon = (
          <MdReportProblem
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_INFO:
        renderedIcon = (
          <MdInfoOutline
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_START:
        renderedIcon = (
          <MdSkipNext
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_STOP:
        renderedIcon = (
          <MdSkipPrevious
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_ADD_LIST:
        renderedIcon = (
          <MdPlaylistAdd
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_BAN:
        renderedIcon = (
          <FaBan
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_ALLOW:
        renderedIcon = (
          <MdCheck
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_ARROW_LEFT:
        renderedIcon = (
          <MdChevronLeft
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_H_SQUARE:
        renderedIcon = (
          <FaHSquare
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      case ICON_VIDEO:
        renderedIcon = (
          <FaVideo
            size={ size }
            color={ color }
            style={ iconStyle } />
        )
        break
      default:
        renderedIcon = (
          <div style={ { color: 'red' } }>
            N/A
          </div>
        )
    }
    return renderedIcon
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  rotate: PropTypes.number,
  style: PropTypes.object,
  location: PropTypes.string,
}

Icon.defaultProps = {
  size: 24,
  rotate: 0,
  style: {},
  color: 'inherit',
  location: "left",
}

export default Icon
