import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import getConfigOrEnvVariable from '../utils/ConfigHelper'

import StartupActions from '../redux/StartupRedux'

import RouterContainer from './RouterContainer'

export class RootContainer extends Component {
  componentDidMount() {
    const { startup } = this.props
    startup()
  }

  getHostname = (url) => {
    // run against regex
    const matches = url.match(/^https?:\/\/([^\/?#]+)(?:[\/?#]|$)/i) /* eslint-disable-line */
    // extract hostname (will be null if no match is found)
    return matches && matches[1]
  }

  render() {
    const { appConfig } = this.props
    // setup apiBaseUrl
    const apiBaseUrl = getConfigOrEnvVariable(appConfig.apiBaseUrl, process.env.REACT_APP_API_BASE_URL)
    // setup devConnectSrc
    const devConnectSrc = getConfigOrEnvVariable(appConfig.devConnectSrc, process.env.REACT_APP_DEV_CONNECT_SRC)
    // setup devScriptSrc
    const devScriptSrc = getConfigOrEnvVariable(appConfig.devScriptSrc, process.env.REACT_APP_DEV_SCRIPT_SRC)
    const notificationHubUrl = getConfigOrEnvVariable(appConfig.notificationHubUrl, process.env.REACT_APP_NOTIFICATION_HUB_URL)
    const notificationHubHostName = this.getHostname(notificationHubUrl)
    // setup configurable instance for docker (default to login.microsoftonline.com)
    const instance = getConfigOrEnvVariable(appConfig.authority, "https://login.microsoftonline.com")
    return (
      <div>
        <Helmet
          defaultTitle="myUCB Insights"
          titleTemplate="%s - myUCB Insights">
          <meta charSet="utf-8" />
          <meta name="description" content="myUCB Insights" />
        </Helmet>
        <RouterContainer />
      </div>
    )
  }
}

RootContainer.propTypes = {
  startup: PropTypes.func.isRequired,
  appConfig: PropTypes.object.isRequired,
}

export const mapDispatchToProps = dispatch => ({ startup: () => dispatch(StartupActions.startup()) })

export default connect(null, mapDispatchToProps)(RootContainer)
