import { AppInsights } from 'applicationinsights-js'
import history from './HistoryHelper'

const ReactAppInsights = {
  init(appInsightsOptions) {
    // call downloadAndSetup to download full ApplicationInsights script from CDN and initialize it with instrumentation key
    AppInsights.downloadAndSetup(appInsightsOptions)

    // track the new page view on any router change and display url
    history.listen((location) => {
      AppInsights.trackPageView(location.pathname)
    })
  },
}

export default ReactAppInsights
