import React from 'react'
import PropTypes from 'prop-types'
import { Route, Link } from 'react-router-dom'
import { isNil } from 'ramda'
import cc from 'classcat'

import CollapsePickerItem from '../collapsePicker/CollapsePickerItem'

const PatientLink = (props) => {
  const { patientId, handleClick, patientClass, parentPath } = props
  if (!isNil(patientId)) {
    return (
      <Route
        path={ `${parentPath}/patient/${patientId}` }
        children={ ({ match }) => ( // eslint-disable-line react/no-children-prop
          <CollapsePickerItem
            onItemClick={ handleClick }
            itemClass={ cc([{ selected: match }, patientClass]) }>
            <Link
              to={ `${parentPath}/patient/${patientId}` }
              tabIndex={ -1 }>
              { `Patient ${patientId}` }
            </Link>
          </CollapsePickerItem>
        ) } />
    )
  }
  return null
}

PatientLink.propTypes = {
  patientId: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  patientClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  parentPath: PropTypes.string.isRequired,
}

PatientLink.defaultProps = { patientClass: [] }

export default PatientLink
