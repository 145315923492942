import { call, put } from 'redux-saga/effects'
import { push } from 'react-router-redux'

import { backendFormat, setUtcTimeForDate } from '../utils/MomentHelper'
import addTitleToErrorObject from '../utils/ErrorHelper'

import PatientActions from '../redux/PatientRedux'
import VisitActions from '../redux/VisitRedux'
import SetActions from '../redux/SetRedux'
import PdiActions from '../redux/PdiRedux'

import queries from '../config/ApiConfig'
import sortById from '../utils/SortingHelper'
import { isPureEngageStudy } from '../utils/StudyHelper'
import { extractDataFromResponse } from '../utils/DataHelper'

export function* fetchPatients(api, action) {
  const { studyId, countryId, siteId, includeDetails } = action
  const { ok, data } = yield call(api.get, queries.Patients(studyId, countryId, siteId), { includeDetails })
  const embeddedData = extractDataFromResponse(data)
  if (ok) {
    const patientsSortedById = sortById(embeddedData)
    yield put(PatientActions.fetchPatientsSuccess(patientsSortedById))
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading patients failed`)
    yield put(PatientActions.fetchPatientsFailure(errorObject))
  }
}

export function* fetchPatient(api, action) {
  const { studyId, patientId, includeDetails } = action
  const { ok, data } = yield call(api.get, queries.Patient(studyId, patientId), { includeDetails })
  const embeddedData = extractDataFromResponse(data)
  if (ok) {
    yield put(PatientActions.fetchPatientSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading patient ${patientId} failed`)
    yield put(PatientActions.fetchPatientFailure(errorObject))
  }
}

export function* fetchAvailablePatientIds(api, action) {
  const { studyId, countryId, siteId } = action
  const { ok, data } = yield call(api.get, queries.AvailablePatientIds(studyId, countryId, siteId))
  const embeddedData = extractDataFromResponse(data)
  if (ok) {
    yield put(PatientActions.fetchAvailablePatientIdsSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading available patient IDs failed`)
    yield put(PatientActions.fetchAvailablePatientIdsFailure(errorObject))
  }
}

export function* addPatient(api, action) {
  const {
    studyId,
    countryId,
    siteId,
    patientId,
    studyType,
    newPatient,
  } = action
  let queryToAddPatient = ""

  if (isPureEngageStudy({ studyType })) {
    queryToAddPatient = queries.AddPatientToEngageStudy(studyId, countryId, siteId, patientId)
  } else {
    queryToAddPatient = queries.AddPatient(studyId, countryId, siteId, patientId)
  }
  const { ok, data } = yield call(api.put, queryToAddPatient, newPatient)
  const embeddedData = extractDataFromResponse(data)
  if (ok) {
    const urlOfPatient = `/study/${studyId}/site/${siteId}/patient/${patientId}`
    yield put(push(urlOfPatient))
    yield put(PatientActions.addPatientSuccess(embeddedData))
    yield put(SetActions.addPatientToSitePatientsSuccess(embeddedData))
    yield put(PatientActions.fetchPatients(studyId, countryId, siteId, true))
    yield put(PatientActions.fetchPatient(studyId, patientId, true))
    yield put(PdiActions.fetchAllPdis(studyId, patientId))
    yield put(VisitActions.fetchVisits(studyId, patientId, true))
  } else {
    const errorObject = addTitleToErrorObject(data, `Adding patient failed`)
    yield put(PatientActions.addPatientFailure(errorObject))
  }
}

export function* changePatientId(api, action) {
  const { studyId, newPatientId, patientId, credentials, siteId, countryId, reason } = action
  const { ok, data } = yield call(api.put, queries.ChangePatientId(studyId, patientId), {
    newPatientId,
    reason,
    userName: credentials.userName,
    password: credentials.userPwd,
  })
  if (ok) {
    const urlOfPatient = `/study/${studyId}/site/${siteId}/patient/${newPatientId}`
    yield put(push(urlOfPatient))
    yield put(PatientActions.changePatientIdSuccess(newPatientId, patientId))
    yield put(SetActions.changePatientFromSitePatientsSuccess(newPatientId, patientId))
    yield put(PatientActions.fetchPatients(studyId, countryId, siteId, true))
    yield put(PatientActions.fetchPatient(studyId, newPatientId, true))
    yield put(VisitActions.fetchVisits(studyId, newPatientId, true))
  } else {
    const errorObject = addTitleToErrorObject(data, `Changing patient id failed`)
    yield put(PatientActions.changePatientIdFailure(errorObject))
  }
}

export function* changeDevice(api, action) {
  const { applicationId, studyId, patientId } = action.patientWithChangedDevice
  const { ok, data } = yield call(api.put, queries.ReactivatePatient(studyId, patientId, applicationId))
  const embeddedData = extractDataFromResponse(data)
  if (ok) {
    yield put(PatientActions.changeDeviceSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Changing device failed`)
    yield put(PatientActions.changeDeviceFailure(errorObject))
  }
}

export function* changeLanguage(api, action) {
  const { studyId, patientId, newPrimaryLanguage } = action
  const { ok, data } = yield call(api.put, queries.Patient(studyId, patientId), { primaryLanguage: newPrimaryLanguage })
  if (ok) {
    yield put(PatientActions.changeLanguageSuccess(newPrimaryLanguage))
  } else {
    const errorObject = addTitleToErrorObject(data, `Changing language failed`)
    yield put(PatientActions.changeLanguageFailure(errorObject))
  }
}

export function* saveScreeningEndDate(api, action) {
  const { studyId, patientId, screeningEndDate } = action
  const screeningEndDateWithUtcTime = setUtcTimeForDate(screeningEndDate)
  const { ok, data } = yield call(api.put, queries.Patient(studyId, patientId), { screeningEndDate: screeningEndDateWithUtcTime.format(backendFormat) })
  const embeddedData = extractDataFromResponse(data)
  if (ok) {
    yield put(PatientActions.saveScreeningEndDateSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Saving screening end date failed`)
    yield put(PatientActions.saveScreeningEndDateFailure(errorObject))
  }
}

export function* saveBaselineStartDate(api, action) {
  const { studyId, patientId, baselineStartDate } = action
  const baselineStartDateWithUtcTime = setUtcTimeForDate(baselineStartDate)
  const { ok, data } = yield call(api.put, queries.Patient(studyId, patientId), { baselineDate: baselineStartDateWithUtcTime.format(backendFormat) })
  const embeddedData = extractDataFromResponse(data)
  if (ok) {
    yield put(PatientActions.saveBaselineStartDateSuccess(embeddedData))
    // TODO investigate if there is no way to make this more efficient as this decreases the clarity of the flow
    // extra fetch patient call is needed here for updating the patient to the latest patient with a fastTrackVisit as property
    yield put(PatientActions.fetchPatient(studyId, patientId, true))
    yield put(VisitActions.fetchVisits(studyId, patientId, true))
  } else {
    const errorObject = addTitleToErrorObject(data, `Saving baseline start date failed`)
    yield put(PatientActions.saveBaselineStartDateFailure(errorObject))
  }
}

export function* requestResetCode(api, action) {
  const { studyId, patientId, credentials } = action
  const { ok, data } = yield call(api.put, queries.RequestResetCode(studyId, patientId), {
    userName: credentials.userName,
    password: credentials.userPwd,
  })
  const embeddedData = extractDataFromResponse(data)
  if (ok) {
    yield put(PatientActions.requestResetCodeSuccess(embeddedData.resetCode))
  } else {
    const errorObject = addTitleToErrorObject(data, `Requesting reset code failed`)
    yield put(PatientActions.requestResetCodeFailure(errorObject))
  }
}
