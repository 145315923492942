import React from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'

const SidebarButton = (props) => {
  const { children, buttonClass, handleClick, id, disabled, tooltip } = props
  const buttonClasses = cc([buttonClass, "sidebar-button", { disabled }])
  return (
    <div
      className={ buttonClasses }
      title={ disabled ? tooltip : null }>
      <div
        onClick={ handleClick }
        id={ id }
        onKeyPress={ handleClick }
        role="button"
        tabIndex={ 0 }
        className={ cc(["sidebar-button-content", { disabled }]) }>
        { children }
      </div>
    </div>
  )
}

SidebarButton.propTypes = {
  children: PropTypes.node.isRequired,
  buttonClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  handleClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
}

SidebarButton.defaultProps = {
  buttonClass: [],
  tooltip: null,
  disabled: false,
}

export default SidebarButton
