import React from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'

const TabBarDropdownItem = (props) => {
  const { title, handleClick, id, disabled, tooltip } = props

  const _handleClick = (event) => {
    if (disabled) {
      event.stopPropagation()
    } else {
      handleClick()
    }
  }

  return (
    <div
      className={ cc(["tab-bar-dropdown-menu-wrapper", { disabled }]) }
      title={ disabled ? tooltip : null }
    >
      <div
        className={ cc(["tab-bar-dropdown-menu-item", { disabled }]) }
        onKeyPress={ _handleClick }
        role="button"
        tabIndex={ 0 }
        onClick={ _handleClick }
        id={ id }>
        { title }
      </div>
    </div>
  )
}


TabBarDropdownItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
}

TabBarDropdownItem.defaultProps = {
  disabled: false,
  tooltip: null,
}

export default TabBarDropdownItem
